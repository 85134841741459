import { Box, Grid } from "@mui/material";
import React from "react";
import Assets from "./ImageContainer";
import TextLabel from "./Fields/TextLabel";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    writeBox: {
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      padding: 6
    },
  };
});
const DashboardSummaryBox = ({ backgroundColor, avtar, count, title, iconColor }) => {
  const { classes } = useStyles();

  return (
    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
      <Box display={"flex"} justifyContent={'space-between'} flex={'wrap'} backgroundColor={backgroundColor} borderRadius={2} padding={1} border={`1px dashed ${iconColor}`}>
        <Assets
          style={{ backgroundColor: iconColor, }}
          className={classes.writeBox}
          src={avtar}
          absolutePath={true}
        />
        <Box display={"flex"} gap={1} flexDirection={"column"}>
            <TextLabel variant={"h5"} fontWeight={600} title={count} textAlign={'end'} />
            <TextLabel variant={"subtitle2"} fontWeight={500} title={title} textOverflow={"ellipsis"} whiteSpace={"nowrap"} overflow={"hidden"} />      
        </Box>
      </Box>
    </Grid>
  );
};

export default DashboardSummaryBox;
