import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    footerMain: {
      zIndex: 1052,
      padding: "10px 24px 10px 24px",
      marginTop: "10px",
      position: "fixed",
      bottom: "0",
      background: theme.palette.bgWhite.main,
      boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.05)",
      [theme.breakpoints.down("md")]: {
        padding: "10px 20px",
        width: `100% !important`,
        marginLeft: "-16px !important",
      },
    },
    miniFooterItems: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "20px",
      [theme.breakpoints.down("md")]: {
        gap: "6px",
      },
    },
    footerContain: {
      fontSize: "14px",
      textAlign: "center",
    },
    footerLinkText: {
      fontSize: "12px",
      fontWeight: "600",
      color: theme.palette.bgDarkPrimary.main,
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    privacyPolicy: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});

const Footer = ({ open }) => {
  const { classes } = useStyles();
  return (
    <>
      <Box
        className={classes.footerMain}
        style={{
          width: !open ? "calc(100% - 48px)" : "calc(100% - 255px)",
          marginLeft: !open ? "48px" : "237px",
        }}
      >
        <Box
          className={classes.miniFooterItems}
          sx={{
            display: "flex",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            textAlign: "center",
          }}
        >
          <Typography className={classes.footerLinkText}>
            {" "}
            © {new Date()?.getFullYear()} Copyright - BAPS
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              paddingRight: "10px",
            }}
            className={classes.privacyPolicy}
          >
            <Typography className={classes.footerLinkText}>
              - Developed by Webito Infotech{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
