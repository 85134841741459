import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, InputLabel, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    customInput: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        padding: "1px 0px 0 5px !important",
        "& .MuiAutocomplete-input": {
          padding: "7.5px 60px 7.5px 5px",
          fontSize: "16px",
        },
      },
    },
    autocompleteOption: {
      backgroundColor: "white !important",
      height: "30px !important",
      "&[aria-selected='true'], &[data-focus='true']": {
        backgroundColor: "white !important",
      },
      "&.Mui-focused": {
        backgroundColor: "rgba(64, 140, 245, 0.12) !important",
      },
      "&:hover": {
        backgroundColor: "rgba(64, 140, 245, 0.12) !important",
      },
    },
    disabledTextField: {
      background: theme?.palette?.bgWhite?.main,
      minWidth: "50px",
      "& .MuiOutlinedInput-input": {
        background: "#EEEEEE",
        textAlign: "left",
        WebkitTextFillColor: theme.palette.bgLightGray.main,
        borderRadius: "0px !important",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "0px 4px 4px 0px",
        background: "#EEEEEE",
        padding: "1px 0px 0 5px !important",
        "& .MuiAutocomplete-input": {
          padding: "7.5px 60px 7.5px 5px",
        },
      },
    },
  };
});

export default function AutoCompleteSearch({
  width,
  height,
  text,
  textmt,
  valid,
  options,
  handleChange,
  name,
  fontWeight,
  disabled,
  defaultValue,
  freeSolo = true,
  clearOnSelect,
  blurOnSelect,
  color,
  onBlur,
  searchValue,
  handleSearch,
  fontSize,
  getOptionLabel,
  chips,
  onKeyPress,
}) {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <>
      {text && (
        <Box
          mb={1}
          display="flex"
          fontSize="12px"
          flexDirection={"row"}
          sx={{
            marginTop: textmt ? textmt : "12px",
          }}
        >
          <InputLabel
            sx={{
              fontWeight: fontWeight ? fontWeight : 400,
              fontSize: fontSize ? fontSize : "15px",
              marginRight: "2px",
              color: color ? color : theme.palette.bgBlack.main,
            }}
          >
            {text}
          </InputLabel>
          {valid && (
            <Typography
              height={"5px"}
              color="#EF627A"
              component={"caption"}
              variant={"body2"}
            >
              *
            </Typography>
          )}
        </Box>
      )}
      <Box
        display="flex"
        className="search_filed"
        bgcolor="white"
        borderRadius={1}
        width={width}
      >
        <Autocomplete
          autoHighlight={false}
          disablePortal
          blurOnSelect={blurOnSelect}
          freeSolo={freeSolo}
          clearOnSelect={clearOnSelect}
          options={options}
          getOptionLabel={(option) => option.name || ""}
          classes={{
            option: classes.autocompleteOption,
          }}
          isOptionEqualToValue={(option, value) => {
            if (value === "" || value === option) {
              return true;
            } else {
              return false;
            }
          }}
          filterOptions={(options, state) => {
            const filteredOptions = options.filter((option) => {
              const isSelected = chips.some(
                (chip) => chip.name === option.name
              );
              const matchesSearch = state.inputValue
                ? option.name
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                : true;
              return !isSelected && matchesSearch;
            });
            return filteredOptions;
          }}
          onChange={(event, newValue) => {
            handleChange(event, newValue);
            handleSearch({ target: { value: "" } });
          }}
          value={null}
          sx={{
            width: width,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.customInput}
              variant="outlined"
              name={name}
              placeholder={text}
              onChange={handleSearch}
              value={searchValue}
              onKeyDown={onKeyPress}
            />
          )}
          ListboxProps={{ style: { maxHeight: "200px" } }}
        />
      </Box>
    </>
  );
}
