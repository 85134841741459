import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Fab,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import TableHeading from "../../Components/Common/CommonTableHeading";
import axios from "../../APiSetUp/axios";
import { lightTheme } from "../../theme";
import dayjs from "dayjs";
import CommonTextField from "../../Components/Common/Fields/TextField";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AutoCompleteMultiSelect from "../../Components/Common/AutoCompleteMultiSelect";
import CommonButton from "../../Components/Common/Button/CommonButton";
import Assets from "../../Components/Common/ImageContainer";
import { makeStyles } from "tss-react/mui";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import { Regex } from "../../Utils/regex";
import AutoCompleteSearch from "../../Components/Common/AutoCompleteSearch";
import { Chip } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DatePicker from "react-multi-date-picker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectDropDown from "../../Components/Common/SelectDropDown";
import TableHeadingTwo from "../../Components/Common/CommonTableHeadingTwo";

const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "8px",
      backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
      color: lightTheme.palette.primary.main,
      cursor: "pointer",
    },
    viewBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightSuccess.main,
      backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightRed.main,
      backgroundColor: lightTheme.palette.bgLightExtraRed.main,
      cursor: "pointer",
    },
    deleteBox2: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightRed.main,
      backgroundColor: lightTheme.palette.bgLightExtraRed.main,
      cursor: "pointer",
    },
    cancelButton: {
      backgroundColor: "#6e7881",
    },
    chip: {
      height: "32px !important",
      "& span": {
        whiteSpace: "wrap",
      },
      [theme.breakpoints.down("md")]: {
        height: "38px !important",
      },
      backgroundColor: `${lightTheme.palette.primary.main} !important`,
      color: "#fff !important",
      margin: "4px",
      "& .MuiChip-deleteIcon": {
        color: "#fff !important",
      },
    },
  };
});

const EditForm = ({
  data,
  setData,
  santNamavliDetails,
  handleChange,
  onEditSubmit,
  addVehicle,
  deleteVehicle,
  onCancle,
  error,
  setError,
  mahapoojasantChips,
  setMahapoojasantChips,
  thalSantChips,
  setThalSantChips,
  searchValue,
  setSearchValue,
  vehicleChips,
  setVehicleChips,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [mahapoojasant, setMahapoojasant] = useState("");
  const [thalSant, setThalSant] = useState("");

  const handleChangeAutoComplete = (name, newValue) => {
    if (name === "mahapoojasant") {
      if (
        newValue &&
        !mahapoojasantChips.some(
          (chip) => chip.name?.toLowerCase() === newValue.name?.toLowerCase()
        )
      ) {
        const updatedChips = [...mahapoojasantChips, newValue];
        setMahapoojasantChips(updatedChips);
        setSearchValue({
          ...searchValue,
          mahapoojasant: "",
        });
      }
    } else if (name === "thalSant") {
      if (
        newValue &&
        !thalSantChips.some(
          (chip) => chip.name?.toLowerCase() === newValue.name?.toLowerCase()
        )
      ) {
        const updatedChips = [...thalSantChips, newValue];
        setThalSantChips(updatedChips);
        setSearchValue({
          ...searchValue,
          ThalSant: "",
        });
      }
    }
  };

  const handleAddChip = (name) => (value) => {
    if (name === "mahapoojasant") {
      if (value && !mahapoojasantChips.some((chip) => chip.name === value)) {
        const newChip = { _id: null, name: value };
        const updatedChips = [...mahapoojasantChips, newChip];
        const removeOther = updatedChips?.filter(
          (e) => e?.name !== "મહેમાન સંત"
        );
        setMahapoojasantChips(removeOther);
        setMahapoojasant("");
        setSearchValue({
          ...searchValue,
          mahapoojasant: "",
        });
      }
    } else if (name === "thalSant") {
      if (value && !thalSantChips.some((chip) => chip.name === value)) {
        const newChip = { _id: null, name: value };
        const updatedChips = [...thalSantChips, newChip];
        const removeOther = updatedChips?.filter(
          (e) => e?.name !== "મહેમાન સંત"
        );
        setThalSantChips(removeOther);
        setThalSant("");
        setSearchValue({
          ...searchValue,
          ThalSant: "",
        });
      }
    }
  };

  const handleDeleteChip = (name) => (index, chipToDelete) => {
    if (name === "mahapoojasant") {
      const updatedChips = mahapoojasantChips.filter((chip, i) => i !== index);
      setMahapoojasantChips(updatedChips);
    } else if (name === "thalSant") {
      const updatedChips = thalSantChips.filter((chip, i) => i !== index);
      setThalSantChips(updatedChips);
    }
  };

  const handleSearch = (name) => (event) => {
    if (name === "mahapoojasant") {
      setSearchValue({
        ...searchValue,
        mahapoojasant: event.target.value,
      });
    } else if (name === "thalSant") {
      setSearchValue({
        ...searchValue,
        ThalSant: event.target.value,
      });
    }
  };
  const handleChangeInputAutoComplete = (name) => (index, value, isButton) => {
    if (name === "santName" && value?.name) {
      if (
        value?.name &&
        !data?.vehicleDetails?.[index]?.santName.some(
          (chip) => chip.name?.toLowerCase() === value?.name?.toLowerCase()
        )
      ) {
        const updateData = data?.vehicleDetails.map((item, i) =>
          i === index
            ? {
                ...item,
                vehicleSantNameInput: "",
                santName: [
                  ...item.santName,
                  { _id: value._id || null, name: value.name },
                ]?.filter((e) =>
                  isButton ? e?.name !== "મહેમાન સંત" : e?.name
                ),
                thalSant: "",
              }
            : { ...item }
        );
        setData({
          ...data,
          vehicleDetails: updateData,
        });
      }
    }
  };
  const handleChangeInputSearch = (name) => (index, value) => {
    if (name === "santName" && value) {
      console.log("value", value);
      const updateData = data?.vehicleDetails.map((item, i) =>
        i === index ? { ...item, vehicleSantNameInput: value } : { ...item }
      );
      console.log("updateData56", updateData);
      setData({
        ...data,
        vehicleDetails: updateData,
      });
    }
  };
  const handleChangeDelete = (name) => (mainIndex, index, value) => {
    if (name === "santName") {
      const updateData = data?.vehicleDetails.map((item, i) =>
        i === mainIndex
          ? {
              ...item,
              santName: item.santName.filter((ele, idx) => idx !== index),
            }
          : { ...item }
      );
      setData({
        ...data,
        vehicleDetails: updateData,
      });
    }
  };

  const santName = data?.vehicleDetails?.map((e) => e?.santName)?.flat();
  const mixAllSelectedOption = [
    ...mahapoojasantChips,
    ...thalSantChips,
    ...santName,
  ];
  const selectedNames = mixAllSelectedOption.map((option) => option.name);
  const filterSantNamavliDetails = santNamavliDetails.filter(
    (detail) => !selectedNames.includes(detail.name)
  );

  return (
    <>
      <Box
        sx={{ backgroundColor: theme?.palette?.bgDefaultPrimaryLight?.main }}
      >
        <Grid
          container
          spacing={1}
          xs={12}
          md={12}
          lg={12}
          sm={12}
          p={2}
          mt={0}
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CommonTextField
              fontWeight={400}
              text={"નામ"}
              placeholder={"Enter નામ"}
              type="text"
              name="name"
              value={data?.name}
              onChange={handleChange}
            />
            <TextLabel
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.name ? error?.name : ""}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CommonTextField
              fontWeight={400}
              text={"અટક"}
              placeholder={"Enter અટક"}
              type="text"
              name="surname"
              value={data?.surname}
              onChange={handleChange}
            />
            <TextLabel
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.surname ? error?.surname : ""}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3}>
            <Box
              mt={0.5}
              // mb={1}
              display="flex"
              fontSize="12px"
              flexDirection={"row"}
            >
              <InputLabel
                sx={{
                  marginRight: "3px",
                  fontWeight: 400,
                  fontSize: "14px",
                  color: " #151D48",
                  padding: "3px",
                }}
              >
                {" "}
                તારીખ
              </InputLabel>
            </Box>
            <DatePicker
              value={data?.date}
              onChange={(e, newValue) => {
                setData({ ...data, date: new Date(e) });
              }}
              format="DD/MM/YYYY"
              className="green"
              placeholder="Select Date"
              inputClass="rmdp-input"
              containerStyle={{ width: "100%" }}
              editable={false}
              minDate={new Date()}
            />
            <TextLabel
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.date ? error?.date : ""}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3}>
            <CommonTextField
              fontWeight={400}
              text={"વાર"}
              placeholder={"વાર"}
              type="text"
              name="day"
              value={data?.day}
            />
            <TextLabel
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.day ? error?.day : ""}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box mt={0.5} display="flex" flexDirection={"row"}>
                <InputLabel
                  sx={{
                    marginRight: "3px",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: " #151D48",
                    padding: "3px",
                  }}
                >
                  સમય
                </InputLabel>
              </Box>
              <DemoContainer
                sx={{
                  paddingTop: "0px !important",
                  "& div": {
                    minWidth: "auto !important",
                  },
                  backgroundColor: "white !important",
                }}
                components={["TimePicker"]}
              >
                <TimePicker
                  className="timePicker"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px !important",
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 10px !important",
                      },
                    },
                  }}
                  value={dayjs(data?.time)}
                  label=""
                  onChange={(newValue) => handleChange(newValue, "time")}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3}>
            <CommonTextField
              fontWeight={400}
              text={"મોબાઈલ.નં"}
              placeholder={"મોબાઈલ.નં"}
              type="text"
              name="phoneNumber"
              value={data.phoneNumber}
              onChange={handleChange}
            />
            {error?.phoneNumber &&
              !data?.phoneNumber
                ?.toString()
                ?.match(Regex.mobileNumberRegex) && (
                <TextLabel
                  fontSize={"12px"}
                  color={"red"}
                  title={error?.phoneNumber}
                />
              )}
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CommonTextField
                        fontWeight={400}
                        text={'સરનામું'}
                        placeholder={'સરનામું'}
                        type='text'
                        name='address'
                        value={data.address}
                        onChange={handleChange}
                        multiline
                        rows={1}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.address ? error?.address : ""} />
                </Grid> */}
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <CommonTextField
              fontWeight={400}
              text={"ઘર/મક।ન નંબર."}
              placeholder={"Enter ઘર/મક।ન નંબર."}
              type="text"
              name="houseNo"
              value={data?.houseNo}
              onChange={handleChange}
              rows={1}
            />
            <TextLabel
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.houseNo ? error?.houseNo : ""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={9}>
            <CommonTextField
              fontWeight={400}
              text={"સોસાયટી/એપાર્ટમેન્ટ નું નામ"}
              placeholder={"Enter સોસાયટી/એપાર્ટમેન્ટ નું નામ"}
              type="text"
              name="societyName"
              value={data?.societyName}
              onChange={handleChange}
              rows={1}
            />
            <TextLabel
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.societyName ? error?.societyName : ""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CommonTextField
              fontWeight={400}
              text={"સ્થાન દ્વારા નજીક"}
              placeholder={"Enter સ્થાન દ્વારા નજીક"}
              type="text"
              name="landmark"
              value={data?.landmark}
              onChange={handleChange}
              rows={1}
            />
            <TextLabel
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.landmark ? error?.landmark : ""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CommonTextField
              fontWeight={400}
              text={"વિસ્તાર"}
              placeholder={"Enter વિસ્તાર"}
              type="text"
              name="area"
              value={data?.area}
              onChange={handleChange}
              rows={1}
            />
            <TextLabel
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.area ? error?.area : ""}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <CommonTextField
              fontWeight={400}
              text={"નોંધ કયા નિમિતે"}
              placeholder={"Enter નોંધ કયા નિમિતે"}
              type="text"
              name="note"
              value={data?.note}
              onChange={(e) => handleChange(e, false)}
              multiline
              rows={1}
            />
            <TextLabel
              style={{ marginTop: "14px" }}
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.note ? error?.note : ""}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <CommonTextField
              fontWeight={400}
              text={"એડ્રેસ લિંક"}
              placeholder={"Enter એડ્રેસ લિંક"}
              type="text"
              name="description"
              value={data?.description}
              onChange={(e) => handleChange(e, false)}
              multiline
              rows={1}
            />
            <TextLabel
              style={{ marginTop: "14px" }}
              fontSize={"12px"}
              color={"red"}
              fontWeight={"400"}
              title={!data?.description ? error?.description : ""}
            />
          </Grid>

          {/* મહા પૂજા માટે પધારનાર પૂ. સંતો (સવારે ૯:૦૦ વાગ્યે મંદિરેથી નીકળવું.) */}

          <Grid item xs={12} sm={12} md={6}>
            <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"end"}
              width={"100%"}
              gap={1}
            >
              {mahapoojasantChips
                ?.map((e) => e?.name)
                ?.includes("મહેમાન સંત") ? (
                <>
                  <Grid item md={11} xs={11}>
                    <CommonTextField
                      fontWeight={400}
                      text={
                        "મહા પૂજા માટે પધારનાર પૂ. સંતો (સવારે ૯:૦૦ વાગ્યે મંદિરેથી નીકળવું.)"
                      }
                      placeholder={
                        "Enter મહા પૂજા માટે પધારનાર પૂ. સંતો (સવારે ૯:૦૦ વાગ્યે મંદિરેથી નીકળવું.)"
                      }
                      type="text"
                      name="mahapoojasant"
                      value={mahapoojasant}
                      onChange={(e) => {
                        const inputValue = e?.target?.value;
                        if (inputValue.trim() !== "") {
                          setMahapoojasant(inputValue);
                        } else {
                          setMahapoojasant("");
                        }
                      }}
                      rows={1}
                      autoFocus
                    />
                  </Grid>
                  <Grid item md={1} xs={1}>
                    {mahapoojasant?.length === 0 ? (
                      <Fab
                        color="primary"
                        size="small"
                        aria-label="add"
                        sx={{
                          cursor: "pointer",
                          width: "40px",
                        }}
                      >
                        <ArrowBackIcon
                          fontSize="15px"
                          onClick={() => {
                            const removeOther = mahapoojasantChips?.filter(
                              (e) => e?.name !== "મહેમાન સંત"
                            );
                            setMahapoojasantChips(removeOther);
                          }}
                        />
                      </Fab>
                    ) : (
                      <Fab
                        color="primary"
                        size="small"
                        aria-label="add"
                        sx={{ cursor: "pointer", width: "40px" }}
                      >
                        <AddIcon
                          fontSize="15px"
                          onClick={() => {
                            handleAddChip("mahapoojasant")(mahapoojasant);
                          }}
                        />
                      </Fab>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item md={11} xs={11}>
                  <Box
                    mb={1}
                    display="flex"
                    fontSize="12px"
                    flexDirection={"row"}
                    sx={{
                      marginTop: "12px",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontWeight: 400,
                        fontSize: "15px",
                        marginRight: "2px",
                        color: theme.palette.bgBlack.main,
                      }}
                    >
                      મહા પૂજા માટે પધારનાર પૂ. સંતો (સવારે ૯:૦૦ વાગ્યે મંદિરેથી
                      નીકળવું.)
                    </InputLabel>
                  </Box>
                  <SelectDropDown
                    size={"small"}
                    values={
                      [{ name: "મહેમાન સંત" }, ...filterSantNamavliDetails] ||
                      []
                    }
                    name="મહા પૂજા માટે પધારનાર પૂ. સંતો (સવારે ૯:૦૦ વાગ્યે મંદિરેથી નીકળવું.)"
                    onChange={(e) =>
                      handleChangeAutoComplete("mahapoojasant", e.target.value)
                    }
                    sx={{ backgroundColor: "white !important" }}
                  />
                </Grid>
              )}
            </Box>
            {error.mahapoojasant && (
              <TextLabel
                fontSize={"12px"}
                color={"red"}
                fontWeight={"400"}
                title={error.mahapoojasant}
              />
            )}
            <Grid item xs={12} sm={12} md={12} mt={1}>
              <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                {mahapoojasantChips
                  ?.filter((e) => e?.name !== "મહેમાન સંત")
                  ?.map((option, index) => (
                    <Chip
                      key={index}
                      label={option?.name}
                      onDelete={() =>
                        handleDeleteChip("mahapoojasant")(index, option)
                      }
                      // style={{ margin: "4px" }}
                      className={classes.chip}
                    />
                  ))}
              </Box>
            </Grid>
          </Grid>

          {/* થાળ માટે પધારનાર પૂ. સંતો (સવારે ૧૦:૦૦ વાગ્યે મંદિરેથી નીકળવું.) */}

          <Grid item xs={12} sm={12} md={6}>
            <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"end"}
              width={"100%"}
              gap={1}
            >
              {thalSantChips?.map((e) => e?.name)?.includes("મહેમાન સંત") ? (
                <>
                  <Grid item md={11} xs={11}>
                    <CommonTextField
                      fontWeight={400}
                      text={
                        "થાળ માટે પધારનાર પૂ. સંતો (સવારે ૧૦:૦૦ વાગ્યે મંદિરેથી નીકળવું.)"
                      }
                      placeholder={
                        "Enter થાળ માટે પધારનાર પૂ. સંતો (સવારે ૧૦:૦૦ વાગ્યે મંદિરેથી નીકળવું.)"
                      }
                      type="text"
                      name="thalSant"
                      value={thalSant}
                      onChange={(e) => {
                        const inputValue = e?.target?.value;
                        if (inputValue.trim() !== "") {
                          setThalSant(inputValue);
                        } else {
                          setThalSant("");
                        }
                      }}
                      rows={1}
                      autoFocus
                    />
                  </Grid>
                  <Grid item md={1} xs={1}>
                    {thalSant?.length === 0 ? (
                      <Fab
                        color="primary"
                        size="small"
                        aria-label="add"
                        sx={{
                          cursor: "pointer",
                          width: "40px",
                        }}
                      >
                        <ArrowBackIcon
                          fontSize="15px"
                          onClick={() => {
                            const removeOther = thalSantChips?.filter(
                              (e) => e?.name !== "મહેમાન સંત"
                            );
                            setThalSantChips(removeOther);
                          }}
                        />
                      </Fab>
                    ) : (
                      <Fab
                        color="primary"
                        size="small"
                        aria-label="add"
                        sx={{ cursor: "pointer", width: "40px" }}
                      >
                        <AddIcon
                          fontSize="15px"
                          onClick={() => {
                            handleAddChip("thalSant")(thalSant);
                          }}
                        />
                      </Fab>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item md={11} xs={11}>
                  <Box
                    mb={1}
                    display="flex"
                    fontSize="12px"
                    flexDirection={"row"}
                    sx={{
                      marginTop: "12px",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontWeight: 400,
                        fontSize: "15px",
                        marginRight: "2px",
                        color: theme.palette.bgBlack.main,
                      }}
                    >
                      થાળ માટે પધારનાર પૂ. સંતો (સવારે ૧૦:૦૦ વાગ્યે મંદિરેથી
                      નીકળવું.)
                    </InputLabel>
                  </Box>
                  <SelectDropDown
                    size={"small"}
                    values={
                      [{ name: "મહેમાન સંત" }, ...filterSantNamavliDetails] ||
                      []
                    }
                    name="થાળ માટે પધારનાર પૂ. સંતો (સવારે ૧૦:૦૦ વાગ્યે મંદિરેથી નીકળવું.)"
                    onChange={(e) =>
                      handleChangeAutoComplete("thalSant", e.target.value)
                    }
                    sx={{ backgroundColor: "white !important" }}
                  />
                </Grid>
              )}
            </Box>
            {error.thalSant && (
              <TextLabel
                fontSize={"12px"}
                color={"red"}
                fontWeight={"400"}
                title={error.thalSant}
              />
            )}
            <Grid item xs={12} sm={12} md={10} mt={1}>
              <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                {thalSantChips
                  ?.filter((e) => e?.name !== "મહેમાન સંત")
                  .map((option, index) => (
                    <Chip
                      key={index}
                      label={option.name}
                      onDelete={() =>
                        handleDeleteChip("thalSant")(index, option)
                      }
                      // style={{ margin: "4px" }}
                      className={classes.chip}
                    />
                  ))}
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableHeadingTwo
              title="વાહન અંગેની વિગતો"
              buttonText={"Add Vehicle"}
              onClick={addVehicle}
              removeSearchField
              titleBgcolor={"red"}
            />
          </Grid>

          {data?.vehicleDetails?.map((vehicle, index) => (
            <React.Fragment key={index}>
              {index !== 0 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextLabel title={`ગાડી - ${index + 1}`} />
              </Grid>

              {/* મહા પૂજા માટે પધારનાર પૂ. સંતો (સવારે ૯:૦૦ વાગ્યે મંદિરેથી નીકળવું.) */}

              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box
                  display={"flex"}
                  justifyContent={"start"}
                  alignItems={"end"}
                  width={"100%"}
                  gap={1}
                >
                  {vehicle?.santName
                    ?.map((e) => e?.name)
                    ?.includes("મહેમાન સંત") ? (
                    <>
                      <Grid item md={11} xs={11}>
                        <CommonTextField
                          fontWeight={400}
                          text={
                            "પધારનાર પૂ. સંતો (સવારે ૧૧:૧૫ વાગયે મંદિરેથી નીકળવું.)"
                          }
                          placeholder={
                            "Enter પધારનાર પૂ. સંતો (સવારે ૧૧:૧૫ વાગયે મંદિરેથી નીકળવું.)"
                          }
                          type="text"
                          name="thalSant"
                          value={vehicle?.thalSant}
                          onChange={(e) => {
                            const inputValue = e?.target?.value;
                            if (inputValue.trim() !== "") {
                              const modifyData = { ...data };
                              modifyData["vehicleDetails"][index]["thalSant"] =
                                inputValue;
                              setData(modifyData);
                            } else {
                              const modifyData = { ...data };
                              modifyData["vehicleDetails"][index]["thalSant"] =
                                "";
                              setData(modifyData);
                            }
                          }}
                          rows={1}
                          autoFocus
                        />
                      </Grid>
                      <Grid item md={1} xs={1}>
                        {!vehicle?.thalSant ? (
                          <Fab
                            color="primary"
                            size="small"
                            aria-label="add"
                            sx={{
                              cursor: "pointer",
                              width: "40px",
                            }}
                          >
                            <ArrowBackIcon
                              fontSize="15px"
                              onClick={() => {
                                const updateData = data?.vehicleDetails.map(
                                  (item, i) =>
                                    i === index
                                      ? {
                                          ...item,
                                          vehicleSantNameInput: "",
                                          santName: item.santName?.filter(
                                            (e) => e?.name !== "મહેમાન સંત"
                                          ),
                                          thalSant: "",
                                        }
                                      : { ...item }
                                );
                                setData({
                                  ...data,
                                  vehicleDetails: updateData,
                                });
                              }}
                            />
                          </Fab>
                        ) : (
                          <Fab
                            color="primary"
                            size="small"
                            aria-label="add"
                            sx={{ cursor: "pointer", width: "40px" }}
                          >
                            <AddIcon
                              fontSize="15px"
                              onClick={() => {
                                handleChangeInputAutoComplete("santName")(
                                  index,
                                  {
                                    name: vehicle?.thalSant,
                                  },
                                  true
                                );
                              }}
                            />
                          </Fab>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={11} xs={11}>
                        <Box
                          mb={1}
                          display="flex"
                          fontSize="12px"
                          flexDirection={"row"}
                          sx={{
                            marginTop: "12px",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontWeight: 400,
                              fontSize: "15px",
                              marginRight: "2px",
                              color: theme.palette.bgBlack.main,
                            }}
                          >
                            પધારનાર પૂ. સંતો (સવારે ૧૧:૧૫ વાગયે મંદિરેથી
                            નીકળવું.)
                          </InputLabel>
                        </Box>
                        <SelectDropDown
                          size={"small"}
                          values={
                            [
                              { name: "મહેમાન સંત" },
                              ...filterSantNamavliDetails,
                            ] || []
                          }
                          name="પધારનાર પૂ. સંતો (સવારે ૧૧:૧૫ વાગયે મંદિરેથી નીકળવું.)"
                          onChange={(e) =>
                            handleChangeInputAutoComplete("santName")(
                              index,
                              e?.target?.value
                            )
                          }
                          sx={{ backgroundColor: "white !important" }}
                        />
                      </Grid>
                      {index !== 0 && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={1}
                          lg={1}
                          sx={{ mt: { xs: 0, sm: 0, md: 5.2, lg: 5.2 } }}
                        >
                          <Assets
                            className={classes.deleteBox2}
                            src={"/assets/icons/delete.svg"}
                            absolutePath={true}
                            onClick={() => deleteVehicle(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Box>
                <Grid item xs={12} sm={12} md={10} mt={1}>
                  <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                    {vehicle?.santName
                      ?.filter((e) => e?.name !== "મહેમાન સંત")
                      ?.map((option, i) => (
                        <Chip
                          key={i}
                          label={option.name}
                          onDelete={() =>
                            handleChangeDelete("santName")(index, i, option)
                          }
                          // style={{ margin: "4px" }}
                          className={classes.chip}
                        />
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "25px",
              paddingBottom: "30px",
              marginRight: "20px",
              alignItems: "center",
            }}
            gap={2}
          >
            <Box style={{ marginLeft: "20px" }}>
              કુલ સંતો - {selectedNames?.length}
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              gap={2}
            >
              <CommonButton
                type="submit"
                onClick={onCancle}
                text={"Cancel"}
                // sx={{"&.css-1horc3a-MuiButtonBase-root-MuiButton-root": {
                //     backgroundColor : "#1A1B2F !important",
                // }}}
                className={classes.cancelButton}
              />
              <CommonButton
                type="submit"
                onClick={() => onEditSubmit(data?._id)}
                text={"Save"}
              />
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default EditForm;
