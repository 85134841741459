import React from 'react'

const DiscountIcon = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.31853 19.5472L3.29188 17.5206C2.46521 16.6939 2.46521 15.3339 3.29188 14.5072L5.31853 12.4805C5.66519 12.1338 5.9452 11.4538 5.9452 10.9738V8.10714C5.9452 6.93381 6.9052 5.97384 8.07854 5.97384H10.9452C11.4252 5.97384 12.1052 5.69388 12.4519 5.34722L14.4785 3.32052C15.3052 2.49386 16.6652 2.49386 17.4919 3.32052L19.5186 5.34722C19.8652 5.69388 20.5452 5.97384 21.0252 5.97384H23.8919C25.0652 5.97384 26.0252 6.93381 26.0252 8.10714V10.9738C26.0252 11.4538 26.3052 12.1338 26.6519 12.4805L28.6786 14.5072C29.5052 15.3339 29.5052 16.6939 28.6786 17.5206L26.6519 19.5472C26.3052 19.8939 26.0252 20.5739 26.0252 21.0539V23.9205C26.0252 25.0938 25.0652 26.0539 23.8919 26.0539H21.0252C20.5452 26.0539 19.8652 26.3339 19.5186 26.6805L17.4919 28.7072C16.6652 29.5339 15.3052 29.5339 14.4785 28.7072L12.4519 26.6805C12.1052 26.3339 11.4252 26.0539 10.9452 26.0539H8.07854C6.9052 26.0539 5.9452 25.0938 5.9452 23.9205V21.0539C5.9452 20.5606 5.66519 19.8806 5.31853 19.5472Z" stroke={props.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 20L20 12" stroke={props.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.326 19.3333H19.338" stroke={props.color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.6593 12.6667H12.6713" stroke={props.color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default DiscountIcon