import React from 'react'

const DashboardIcon = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0032 8.1792L14.3744 13.6991L14.5587 16.4735C14.5607 16.7589 14.6053 17.0423 14.6916 17.3147C14.9141 17.8435 15.4496 18.1795 16.0321 18.1561L24.9084 17.5754C25.2928 17.5691 25.664 17.7129 25.9403 17.9751C26.1705 18.1937 26.3192 18.4795 26.3661 18.787L26.3819 18.9737C26.0145 24.0599 22.2789 28.3023 17.2032 29.3974C12.1274 30.4925 6.92248 28.1791 4.41428 23.7133C3.69118 22.4158 3.23953 20.9898 3.08584 19.5187C3.02164 19.0833 2.99337 18.6434 3.0013 18.2034C2.99337 12.7504 6.87662 8.03603 12.3124 6.89951C12.9667 6.79763 13.608 7.14397 13.8704 7.74079C13.9383 7.879 13.9831 8.02702 14.0032 8.1792Z" stroke={props.color} stroke-width="1.5" />
            <path opacity="0.4" d="M29.6664 13.4164L29.657 13.4598L29.6301 13.523L29.6338 13.6965C29.62 13.9263 29.5312 14.1473 29.3784 14.326C29.219 14.512 29.0014 14.6387 28.7618 14.6879L28.6157 14.7079L18.3746 15.3715C18.034 15.4051 17.6948 15.2952 17.4416 15.0693C17.2304 14.8809 17.0954 14.6268 17.0573 14.3529L16.37 4.12675C16.358 4.09217 16.358 4.05469 16.37 4.02011C16.3793 3.73823 16.5034 3.47179 16.7145 3.28031C16.9254 3.08883 17.206 2.98827 17.493 3.00109C23.5729 3.15577 28.6828 7.52772 29.6664 13.4164Z" stroke={props.color} stroke-width="1.5" />
        </svg>
    )
}

export default DashboardIcon