import React, { useEffect, useState } from 'react'
import { Box, Grid, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import TextLabel from '../../Components/Common/Fields/TextLabel';
import { Image_BASE_URL } from '../../APiSetUp/axios';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DataNotFound from '../Common/DataNotFound';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles()((theme) => {
    return {
        siteImagesBox: {
            border: "1px dashed #ccc",
            "&:hover": { backgroundColor: theme.palette.bgLightExtraPrimary.main },
            borderRadius: "8px",
            display: 'flex',
            width: '100%',
            height: '200px',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 1,

        },
        siteAudioBox: {
            border: "1px dashed #ccc",
            "&:hover": { backgroundColor: theme.palette.bgLightExtraPrimary.main },
            borderRadius: "8px",
            display: 'flex',
            width: '100%',
            height: '200px',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'start',
            alignItems: 'center',
            padding: 1,

        },
    };
});

const ViewSite = ({ siteImages }) => {
    const { classes } = useStyles();
    const theme = useTheme()

    const [data, setdata] = useState({
        imageData: [],
        pdfData: [],
        videoData: [],
        audioData: []

    })

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const videoExtensions = ['mp4', 'avi', 'mov'];
    const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
    const audioExtensions = ['mp3', 'wav'];

    const getExtension = (filename) => {
        return filename.split('.').pop().toLowerCase();
    };

    useEffect(() => {
        setdata({
            ...data,
            imageData: siteImages.filter(ele => imageExtensions.includes(getExtension(ele))),
            pdfData: siteImages.filter(ele => documentExtensions.includes(getExtension(ele))),
            videoData: siteImages.filter(ele => videoExtensions.includes(getExtension(ele))),
            audioData: siteImages.filter(ele => audioExtensions.includes(getExtension(ele)))
        })
    }, [siteImages])


    console.log('data', data)
    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px 0px 20px" }} >
                        <TextLabel fontSize={"18px"} fontWeight={"600"} title={'Images'} />
                    </Box>
                </Grid>
                {data?.imageData.length > 0 ? data?.imageData.map((file, index) =>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box component="label" className={classes.siteImagesBox}>
                            <Box display={'flex'} justifyContent={'center'}>
                                <img key={index} src={`${Image_BASE_URL}${file}`} alt="Image" style={{ width: '250px', height: 'auto', }} />
                            </Box>
                        </Box>
                    </Grid>
                ) : <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />}

                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px 0px 20px" }} >
                        <TextLabel fontSize={"18px"} fontWeight={"600"} title={'Video'} />
                    </Box>
                </Grid>
                {data?.videoData.length > 0 ? data?.videoData.map((file, index) =>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box component="label" className={classes.siteImagesBox}>
                            <Box display={'flex'} justifyContent={'center'}>
                                <video key={index} controls style={{ width: '250px', height: 'auto' }}><source src={`${Image_BASE_URL}${file}`} /></video>
                            </Box>
                        </Box>
                    </Grid>
                ) :<DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />}


                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px 0px 20px" }} >
                        <TextLabel fontSize={"18px"} fontWeight={"600"} title={'Pdf'} />
                    </Box>
                </Grid>
                {data?.pdfData.length > 0  ? data?.pdfData.map((file, index) =>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box component="label" className={classes.siteImagesBox} sx={{ cursor: "pointer" }} onClick={() => window.open(`${Image_BASE_URL}${file}`, '_blank')}>
                            <Box display={'flex'} justifyContent={'center'}>
                                <PictureAsPdfIcon fontSize="large" color="error" style={{ height: '130px', maxWidth: '100%' }} />
                            </Box>
                        </Box>
                    </Grid>
                ):<DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />}


                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px 0px 20px" }} >
                        <TextLabel fontSize={"18px"} fontWeight={"600"} title={'Audio'} />
                    </Box>
                </Grid>
                {data?.audioData.length > 0  ? data?.audioData.map((file, index) =>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box component="label" className={classes.siteAudioBox}>
                            <Box display={'flex'} justifyContent={'center'}>
                                <audio class='audioPlayer' controls style={{ height: '130px', maxWidth: '100%', padding: "0px 10px" }}>  <source src={`${Image_BASE_URL}${file}`} /></audio>
                            </Box>
                        </Box>
                    </Grid>
                ):<DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />}


            </Grid>
        </Box>
    )
}

export default ViewSite