import React, { useState } from 'react'
import { Box, Grid, Divider, FormControlLabel, FormGroup, Checkbox, TextField, InputLabel } from "@mui/material";
import { useTheme } from '@mui/styles';
import { makeStyles } from "tss-react/mui";
import TextLabel from '../Common/Fields/TextLabel';
import CommonTextField from '../Common/Fields/TextField';
import CommonButton from '../Common/Button/CommonButton';
import { Regex } from '../../Utils/regex';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import FileUpload from '../Common/uploadButton';
import AutoCompleteSearch from '../Common/commonAutoComplete';
import { Roles, roles } from '../../Utils/enum';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Assets from '../Common/ImageContainer';
import { lightTheme } from '../../theme';
import { useAppContext } from '../../Context/context';
import CommonFileDrop from '../Common/CommonFileDrop';

const useStyles = makeStyles()((theme) => {
    return {
        dateBox: {
            background: theme?.palette?.bgWhite?.main,
            borderRadius: '8px',
            // minWidth: "50px",
            "& .MuiOutlinedInput-root": {
                borderRadius: "5px !important",
            },
            "& .MuiOutlinedInput-input": {
                padding: "12.5px 14px",
                fontSize: "14px !important",
                height: "18px",
            },
            "&:hover": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
                borderRadius: "8px",
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
                borderWidth: "1px !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                borderRadius: '8px',
            }
        },
        customLabel: {
            "& .MuiTypography-root": {
                fontSize: "15px",
                color: "#151D48",
            },
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
            height: '26px',
        },
    };
});
const AddSite = ({ data, error, handleChange, isEdit, onSubmit, setData, handleUpload, handleDeleteFile, siteTypeList, files, setFiles, addAudioFile, deleteFiled, selectedSiteType, setSelectedSiteType, employeList, selectedEmploye, setSelectedEmploye }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();

    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                {user?.userType !== Roles?.Employe && <Grid item xs={12} sm={6} md={4} lg={4}>
                    <AutoCompleteSearch
                        text={"Employe"}
                        placeholder="please select employe"
                        fullWidth
                        backgroundColor="white"
                        name="employe"
                        handleChange={(e, newValue) => setSelectedEmploye(newValue)}
                        options={employeList?.map((e) => e?.name) || []}
                        defaultValue={selectedEmploye}
                        freeSolo
                        blurOnSelect
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!selectedEmploye ? error?.employe : ""} />
                </Grid>}

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Site name'}
                        placeholder={"Please enter site name"}
                        type='text'
                        name='siteName'
                        value={data?.siteName}
                        onChange={handleChange}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.siteName ? error?.siteName : ""} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Location'}
                        placeholder={"Please enter location"}
                        type='text'
                        name='location'
                        value={data?.location}
                        onChange={handleChange}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.location ? error?.siteName : ""} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Latitude'}
                        placeholder={"Please enter latitude."}
                        type='number'
                        name='latitude'
                        value={data?.latitude}
                        onChange={(e) => handleChange(e, false)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.latitude ? error?.latitude : ""} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Longitude'}
                        placeholder={"Please enter longitude."}
                        type='number'
                        name='longitude'
                        value={data?.longitude}
                        onChange={(e) => handleChange(e, false)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.longitude ? error?.longitude : ""} />
                </Grid>
                {console.log("siteTypeList", siteTypeList)}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <AutoCompleteSearch
                        text={"Site type"}
                        placeholder="please select site type"
                        fullWidth
                        backgroundColor="white"
                        name="siteType"
                        handleChange={(e, newValue) => setSelectedSiteType(newValue)}
                        options={siteTypeList?.map((e) => e?.siteType) || []}
                        defaultValue={selectedSiteType}
                        freeSolo
                        blurOnSelect
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!selectedSiteType ? error?.siteType : ""} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <CommonTextField
                        fontWeight={400}
                        text={'Description'}
                        placeholder={"Please enter description"}
                        type='text'
                        name='description'
                        value={data?.description}
                        onChange={(e) => handleChange(e, false)}
                        multiline={true}
                        rows={2}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.description ? error?.description : ""} style={{ marginTop: "36px" }} />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid item xs={12} sm={12} md={12} lg={12} marginTop={1} >
                        <TextLabel fontSize={"14px"} color={"#151D48"} fontWeight={"400"} title={"Upload Audio File"} marginBottom={5} secondText={' (Optional)'} secondTextFontSize="10px" secondTextColor={theme.palette.backgroundDefaultColor.main} />
                    </Grid>
                    <CommonFileDrop
                        isMultiple={true}
                        value={""}
                        handleImgClose={() => { }}
                        values={""}
                        setFieldValue={""}
                        handleImageChange={(e) => handleUpload(e)}
                        handleFileChange
                        sx={{ height: "136px", cursor: "grab" }}
                        accept="image/png,image/jpeg,image/jpg,image/svg,image/webp,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,audio/*,video/*"
                        name="productImage"
                    />

                </Grid>

                {/* <Grid item xs={12} sm={12} md={12} lg={12} marginTop={2}  >
                    <Box style={{ display: 'flex', justifyContent: 'end' }}>
                        <CommonButton
                            startIcon={<AddOutlinedIcon />}
                            width={'110px'}
                            text={`Add File`}
                            type="button"
                            onClick={addAudioFile}
                        />
                    </Box>
                </Grid> */}

                {/* {files.map((audioFile, index) => (
                    <>
                        <Grid item xs={11} sm={11} md={5} lg={5} key={index}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextLabel fontSize={"14px"} color={"#151D48"} fontWeight={"400"} title={"Upload Audio File"} marginBottom={5} secondText={' (Optional)'} secondTextFontSize="10px" secondTextColor={theme.palette.backgroundDefaultColor.main} />
                            </Grid>
                            <FileUpload
                                handleFileChange={(e) => { handleUpload(e.target.files[0], index) }}
                                selectedFile={audioFile}
                                OnDelate={(e) => handleDeleteFile(e, index)}
                            />
                            <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={error?.audioSizeValid} />
                        </Grid>

                        {index >= 1 ? <Grid item xs={1} sm={1} md={1} lg={1} key={index} style={{ display: "flex", alignItems: 'center', marginTop: "28px" }}>
                            <Assets
                                className={classes.deleteBox}
                                src={"/assets/icons/delete.svg"}
                                absolutePath={true}
                                onClick={(ele) => { deleteFiled(ele, index) }}
                            />
                        </Grid> : <Grid item xs={1} sm={1} md={1} lg={1} key={index}></Grid>}
                    </>
                ))} */}


                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <CommonButton
                            width={'160px'}
                            text={`${isEdit ? "Update" : "Submit"} Form`}
                            type="submit"
                            onClick={onSubmit}
                        />
                    </Box>
                </Grid>
            </Grid >
        </Box>
    )
}

export default AddSite