

export const roles = [
    { label: "SuperAdmin", id: 0, },
    { label: "Admin", id: 1, },
]

// UserType is use in header 
export const userType = [
    { label: "SuperAdmin", id: 0, },
    { label: "Admin", id: 1, },
]

export const Roles = {
    SuperAdmin: 0,
    Admin: 1,
};


export const meetingStatus = {
    approvalPending: 0, approved: 1, onGoing: 2, completed: 3, canceled: 4
}
export const feedbackEnum = {
    Good: 5, Medium: 3, Poor: 1
}

export const permissionStatus = {
    view: 0, create: 1, update: 2, delete: 3, active_inactive: 4
}
export const leadStatus = {
    leadAssignedToTelecaller: 1, leadAssignedToReceptionist: 2, leadClose: 3,
}

export const siteStatus = {
    approvalPending: 0, approvedBySuperAdmin: 1, rejectedBySuperAdmin: 2, approvedByAdmin: 3, rejectedByAdmin: 4
}
export const gujaratiDays = {
    Sunday: "રવિવાર",
    Monday: "સોમવાર",
    Tuesday: "મંગળવાર",
    Wednesday: "બુધવાર",
    Thursday: "ગુરુવાર",
    Friday: "શુક્રવાર",
    Saturday: "શનિવાર"
}
