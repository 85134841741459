import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, useTheme } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { makeStyles } from "tss-react/mui";
 
const useStyles = makeStyles()((theme) => {
  return {
    dialog_Main: {
      "& .MuiDialog-paper.MuiPaper-rounded": {
        borderRadius: "8px",
        overflow: "hidden",
        // zIndex: 9999
      },
      "& .MuiDialogTitle-root": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.bgWhite.main,
        // padding: "8px 9px !important"
      },
    },
  };
});

const CommonModal = ({ onClose, open, title, content, maxWidth }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={open}
      className={classes.dialog_Main}
      maxWidth={maxWidth}
      // style={{ zIndex: 2222 }}
    >
      <DialogTitle
        fontWeight={700}
        fontSize={16}
        sx={{ padding: "12px 24px", borderBottom: "1px solid #CDCDCD" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={1}
      >
        {title}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={20}
          height={20}
          borderRadius={1}
          onClick={() => onClose()}
          sx={{
            backgroundColor: theme.palette.bgLightRed.main,
            cursor: "pointer",
          }}
        >
          <CloseOutlinedIcon
            sx={{ color: theme.palette.primary.main, fontSize: "18px", backgroundColor:'#fff' }}
          />
        </Box>
      </DialogTitle>
      <Box
        sx={{
            backgroundColor: theme.palette.bgDefaultPrimaryLight.main,

          overflow: "scroll",
          "::-webkit-scrollbar": { width: "0.5px" },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: {
              backgroundColor: theme.palette.bgDefaultPrimaryLight.main,
            },
          },
        }}
      >
        {content}
      </Box>
    </Dialog>
  );
};
export default CommonModal;
