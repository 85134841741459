import { Box, Fab, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import SectionHeading from "./SectionHeading";
import CommonButton from "./Button/CommonButton";
import { lightTheme } from "../../theme";
import SelectDropDown from "./SelectDropDown";
import CommonSearch from "./CommonSearch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    commonHeader: {
      padding: "12px 0px",
      display: "flex",
      gap: "5px",
      // flexWrap: 'wrap',
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
      color: "#B5090E",
      backgroundColor: "transparent",
      borderRadius: "6px 6px 0 0",
      [theme.breakpoints.down("sm")]: {
        alignItems: "start",
      },
    },
  };
});

const TableHeadingTwo = ({
  title,
  buttonText,
  onClick,
  showSelectDropDown,
  borderBottom,
  removeSearchField,
  handleBack,
  handleSearch,
  fontSize,
  variant,
  ...props
}) => {
  const [data, setData] = useState({});
  const credit = ["Gujarat ", "Gujarat"];
  const { classes } = useStyles();
  const theme = useTheme();

  const variantToFontSize = {
    h1: "40px",
    h2: "36px",
    h3: "30px",
    h4: "26px",
    h5: "24px",
    h6: "20px",
    subtitle1: "18px",
    subtitle2: "16px",
    body1: "14px",
    body2: "12px",
  };
  const [responsiveFontSize, setResponsiveFontSize] = useState(
    fontSize || variantToFontSize[variant]
  );
  useEffect(() => {
    const updateFontSizes = () => {
      const newVariantToFontSize = { ...variantToFontSize };
      if (window.innerWidth <= 600) {
        newVariantToFontSize.h1 = "28px";
        newVariantToFontSize.h2 = "25px";
        newVariantToFontSize.h3 = "24px";
        newVariantToFontSize.h4 = "22px";
        newVariantToFontSize.h5 = "20px";
        newVariantToFontSize.h6 = "18px";
        newVariantToFontSize.subtitle1 = "16px";
        newVariantToFontSize.subtitle2 = "14px";
        newVariantToFontSize.body1 = "12px";
        newVariantToFontSize.body2 = "12px";
      }
      setResponsiveFontSize(fontSize || newVariantToFontSize[variant]);
    };
    window.addEventListener("resize", updateFontSizes);
    updateFontSizes();
    return () => {
      window.removeEventListener("resize", updateFontSizes);
    };
  }, [fontSize, variant]);

  return (
    <Box
      style={{
        borderBottom: borderBottom ? borderBottom : "1px solid #e3e1e1",
      }}
      className={classes.commonHeader}
    >
      <SectionHeading
        title={title}
        style={{
          fontSize: responsiveFontSize || "15px",
          fontWeight: 600,
          //   color: lightTheme.palette.bgWhite.main,
          // marginTop: "5px",
        }}
        variant={variant}
      />
      <Box
        display={"flex"}
        gap={1}
        flexWrap={"wrap"}
        justifyContent={{ lg: "center", md: "center", lg: "center", xs: "end" }}
        alignItems={"center"}
      >
        {!removeSearchField && (
          // <CommonSearch onSearch={onSearch ? onSearch : null}/>
          <Box
            sx={{
              display: { lg: "block", md: "block", sm: "block", xs: "none" },
            }}
          >
            <CommonSearch handleSearch={handleSearch ? handleSearch : null} />
          </Box>
        )}
        {buttonText && (
          <CommonButton
            text={buttonText}
            type="submit"
            // fontSize="13px"
            onClick={onClick}
          />
        )}
        {!removeSearchField && (
          // <CommonSearch onSearch={onSearch ? onSearch : null}/>
          <Box
            sx={{
              display: { lg: "none", md: "none", sm: "none", xs: "block" },
              width: "100%",
            }}
          >
            <CommonSearch handleSearch={handleSearch ? handleSearch : null} />
          </Box>
        )}
        {showSelectDropDown && (
          <SelectDropDown
            fullWidth
            size={"small"}
            width={"130px"}
            values={credit || []}
            value={data?.credit}
            name="state"
            onChange={(e) => {
              setData({ ...data, state: e.target.value });
            }}
          />
        )}
        {handleBack && (
          <Fab
            aria-label="add"
            onClick={handleBack}
            size="small"
            sx={{ backgroundColor: "#fff", boxShadow: "none" }}
          >
            <ArrowBackIcon />
          </Fab>
        )}
        {props?.children}
      </Box>
    </Box>
  );
};

export default TableHeadingTwo;
