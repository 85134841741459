
import React from 'react'

const CalculatorIcon = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 22.75H10C4.57 22.75 2.25 20.43 2.25 15V9C2.25 3.57 4.57 1.25 10 1.25H14C19.43 1.25 21.75 3.57 21.75 9V15C21.75 20.43 19.43 22.75 14 22.75ZM10 2.75C5.39 2.75 3.75 4.39 3.75 9V15C3.75 19.61 5.39 21.25 10 21.25H14C18.61 21.25 20.25 19.61 20.25 15V9C20.25 4.39 18.61 2.75 14 2.75H10Z" fill={props.color} />
            <path d="M15 10.8301H9C7.76 10.8301 6.75 9.82008 6.75 8.58008V7.58008C6.75 6.34008 7.76 5.33008 9 5.33008H15C16.24 5.33008 17.25 6.34008 17.25 7.58008V8.58008C17.25 9.82008 16.24 10.8301 15 10.8301ZM9 6.83008C8.59 6.83008 8.25 7.17008 8.25 7.58008V8.58008C8.25 8.99008 8.59 9.33008 9 9.33008H15C15.41 9.33008 15.75 8.99008 15.75 8.58008V7.58008C15.75 7.17008 15.41 6.83008 15 6.83008H9Z" fill={props.color} />
            <path d="M8.15016 14.9201C8.02016 14.9201 7.89016 14.8901 7.77016 14.8401C7.65016 14.7901 7.54016 14.7201 7.45016 14.6301C7.26016 14.4401 7.16016 14.1901 7.16016 13.9201C7.16016 13.7901 7.18016 13.6601 7.23016 13.5401C7.28016 13.4101 7.35016 13.3101 7.45016 13.2101C7.49016 13.1701 7.54016 13.1201 7.60016 13.0901C7.65016 13.0501 7.71016 13.0201 7.77016 13.0001C7.83016 12.9701 7.90016 12.9501 7.96016 12.9401C8.28016 12.8701 8.63016 12.9801 8.86016 13.2101C8.95016 13.3001 9.03016 13.4101 9.08016 13.5401C9.13016 13.6601 9.16016 13.7901 9.16016 13.9201C9.16016 14.1901 9.05016 14.4401 8.86016 14.6301C8.67016 14.8201 8.42016 14.9201 8.15016 14.9201Z" fill={props.color} />
            <path d="M12.1602 14.9199C11.8902 14.9199 11.6402 14.8199 11.4502 14.6299C11.2602 14.4399 11.1602 14.1899 11.1602 13.9199C11.1602 13.7899 11.1802 13.6599 11.2302 13.5399C11.2802 13.4099 11.3502 13.3099 11.4502 13.2099C11.4902 13.1699 11.5402 13.1199 11.6002 13.0899C11.6502 13.0499 11.7102 13.0199 11.7702 12.9999C11.8302 12.9699 11.9002 12.9499 11.9602 12.9399C12.1502 12.8999 12.3502 12.9199 12.5402 12.9999C12.6602 13.0499 12.7702 13.1199 12.8602 13.2099C12.9502 13.3099 13.0302 13.4099 13.0802 13.5399C13.1302 13.6599 13.1602 13.7899 13.1602 13.9199C13.1602 14.1899 13.0502 14.4399 12.8602 14.6299C12.6702 14.8199 12.4202 14.9199 12.1602 14.9199Z" fill={props.color} />
            <path d="M16.1502 14.9201C16.0202 14.9201 15.8902 14.8901 15.7702 14.8401C15.6502 14.7901 15.5402 14.7201 15.4502 14.6301C15.3502 14.5301 15.2802 14.4201 15.2302 14.3001C15.1802 14.1801 15.1602 14.0501 15.1602 13.9201C15.1602 13.7901 15.1802 13.6601 15.2302 13.5401C15.2802 13.4101 15.3502 13.3101 15.4502 13.2101C15.8202 12.8401 16.4902 12.8401 16.8602 13.2101C17.0502 13.4001 17.1602 13.6601 17.1602 13.9201C17.1602 14.1901 17.0502 14.4401 16.8602 14.6301C16.6802 14.8101 16.4302 14.9201 16.1502 14.9201Z" fill={props.color} />
            <path d="M8.16039 18.9199C7.89039 18.9199 7.64039 18.8199 7.45039 18.6299C7.26039 18.4399 7.15039 18.1899 7.15039 17.9199C7.15039 17.6599 7.26039 17.3999 7.45039 17.2099C7.49039 17.1699 7.55039 17.1199 7.60039 17.0899C7.65039 17.0499 7.71039 17.0199 7.77039 16.9999C7.83039 16.9699 7.90039 16.9499 7.96039 16.9399C8.29039 16.8799 8.63039 16.9799 8.86039 17.2099C8.91039 17.2599 8.95039 17.3099 8.99039 17.3599C9.02039 17.4199 9.05039 17.4799 9.08039 17.5399C9.10039 17.5999 9.12039 17.6599 9.14039 17.7199C9.15039 17.7899 9.16039 17.8499 9.16039 17.9199C9.16039 18.1899 9.05039 18.4399 8.86039 18.6299C8.67039 18.8199 8.42039 18.9199 8.16039 18.9199Z" fill={props.color} />
            <path d="M12.1604 18.9199C11.8904 18.9199 11.6404 18.8199 11.4504 18.6299C11.2604 18.4399 11.1504 18.1899 11.1504 17.9199C11.1504 17.6599 11.2604 17.3999 11.4504 17.2099C11.6804 16.9799 12.0304 16.8799 12.3504 16.9399C12.4104 16.9499 12.4804 16.9699 12.5404 16.9999C12.6004 17.0199 12.6604 17.0499 12.7104 17.0899C12.7604 17.1199 12.8104 17.1699 12.8604 17.2099C13.0504 17.3999 13.1504 17.6599 13.1504 17.9199C13.1504 18.1899 13.0504 18.4399 12.8604 18.6299C12.6704 18.8199 12.4204 18.9199 12.1604 18.9199Z" fill={props.color} />
            <path d="M16.1502 18.92C15.8902 18.92 15.6402 18.82 15.4502 18.63C15.2602 18.44 15.1602 18.19 15.1602 17.92C15.1602 17.66 15.2602 17.4 15.4502 17.21C15.5402 17.12 15.6502 17.05 15.7702 17C16.1402 16.85 16.5902 16.93 16.8602 17.21C17.0502 17.4 17.1502 17.66 17.1502 17.92C17.1502 18.19 17.0502 18.44 16.8602 18.63C16.7702 18.72 16.6602 18.79 16.5402 18.84C16.4202 18.89 16.2902 18.92 16.1502 18.92Z" fill="#292D32" />
        </svg>

    )
}

export default CalculatorIcon