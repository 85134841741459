import React, { useEffect, useState } from "react";
import PaperContainer from "../../Components/Common/PaperContainer";
import {  Grid,} from "@mui/material";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import { useAppContext } from "../../Context/context";
import CommonAreaChart from "../Common/CommonAreaChart";
import WidgetLoader from "../Common/widgetLoader";

const VisitorInsights = () => {
  const { OnUpdateError, toggleLoader } = useAppContext();

  const [visitorInsights, setVisitorInsights] = useState([]);
  const [loading, setLoading] = useState(false)

  const _getDashboardVisitorInsights = () => {
    let body = {};
    setLoading(true)
    axios
      .post(`/visitor_Insights`, body)
      .then((res) => {
        console.log(res,"resresres")
        if (res?.data?.data) {
          setVisitorInsights(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() =>  setLoading(false));
  };

  useEffect(() => {
    _getDashboardVisitorInsights();
  }, []);

  return (
    <Grid item sm={12} xs={12} md={6} lg={5}>
      <PaperContainer sx={{ height: "400px", overflow: "auto" }} elevation={0}>
        <TextLabel
          variant={"h6"}
          fontWeight={600}
          title={"Visitor Insights"}
          style={{ padding: "20px 0px 0px 10px" }}
        />
         {loading ? <WidgetLoader /> : <CommonAreaChart visitorInsights={visitorInsights} />}
      </PaperContainer>
    </Grid>
  )
}

export default VisitorInsights
