import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import TextLabel from "../Common/Fields/TextLabel";
import CommonTextField from "../Common/Fields/TextField";
import CommonButton from "../Common/Button/CommonButton";
import AutoCompleteSearch from "../Common/commonAutoComplete";
import { Regex } from "../../Utils/regex";

const AddSantNamavli = ({
  data,
  error,
  handleChange,
  isEdit,
  onSubmit,
  countries,
  setSelectedCountry,
  santNamavliDetails
}) => {
  useEffect(() => {
    const defaultCountry = "India";
    const defaultCountryObj = countries?.response?.find(
      (country) => country.name === defaultCountry
    );
    if (defaultCountryObj) {
      setSelectedCountry(defaultCountry);
    }
  }, [countries, setSelectedCountry]);

  return (
    <Box>
      <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CommonTextField
            fontWeight={400}
            text={"સંત નામ"}
            placeholder={"Enter સંત નામ"}
            type="text"
            name="name"
            value={data?.name}
            onChange={(e) => handleChange(e, false)}
          />
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.name ? error?.name : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CommonTextField
            fontWeight={400}
            text={"પ્રાથમિકતા"}
            placeholder={"Enter પ્રાથમિકતા"}
            type="number"
            name="priority"
            value={data?.priority}
            onChange={(e) => handleChange(e, false)}
          />
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={
              Number(data?.priority) <= 0 ||
              (santNamavliDetails?.count + 1) < Number(data?.priority)
                ? error?.priority
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "35px",
            }}
          >
            <CommonButton
              width={"280px"}
              text={`${isEdit ? "Update" : "Add"} સંત નામ`}
              type="submit"
              onClick={onSubmit}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddSantNamavli;
