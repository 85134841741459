import React, { useEffect, useState } from "react";
import PaperContainer from "../Common/PaperContainer";
import { Grid, } from "@mui/material";
import axios from "../../APiSetUp/axios";
import DashboardSummaryBox from "../Common/DashboardSummaryBox";
import { useAppContext } from "../../Context/context";
import { globalAmountConfig } from "../../Utils/globalConfig";
import WidgetLoader from "../Common/widgetLoader";

const Summary = () => {
    const { OnUpdateError } = useAppContext();

    const [dashboardSummary, setDashboardSummary] = useState([]);
    const [loading, setLoading] = useState(false)

    const _getDashboardSummary = () => {
        setLoading(true)
        let body = {};
        axios.post(`/dashboard_deatils`, body)
            .then((res) => {
                if (res?.data?.data) setDashboardSummary(res?.data?.data);
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setTimeout(() => {
                setLoading(false)
            }, [500]));
    };

    useEffect(() => {
        _getDashboardSummary()
    }, []);

    return (
        <>
            {loading ? <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer sx={{ padding: '0px' }} elevation={0}>
                    <WidgetLoader />
                </PaperContainer>
            </Grid> : dashboardSummary?.todaySummary ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PaperContainer sx={{ padding: 1 }} elevation={0}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                            {(dashboardSummary?.todaySummary?.todayEmploye || dashboardSummary?.todaySummary?.todayEmploye == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayEmploye)}
                                title={"Today's New Employe"}
                                iconColor={"#FA5A7D"}
                                backgroundColor={"#FFE2E5"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                            {(dashboardSummary?.todaySummary?.todayAdmin || dashboardSummary?.todaySummary?.todayAdmin == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayAdmin)}
                                title={"Today's Total Admin"}
                                iconColor={"#858585"}
                                backgroundColor={"#dbdbdb"}
                                avtar={"/assets/icons/DashboardIcon1.png"}
                            /> : ""}


                            {(dashboardSummary?.todaySummary?.todaySite || dashboardSummary?.todaySummary?.todaySite == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todaySite)}
                                title={"Today's New Site"}
                                iconColor={"#3CD856"}
                                backgroundColor={"#DCFCE7"}
                                avtar={"/assets/icons/DashboardIcon3.png"}
                            /> : ""}

                        </Grid>
                    </PaperContainer>
                </Grid>
            ) : <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer elevation={0}>
                    <WidgetLoader />
                </PaperContainer>
            </Grid>}

            {loading ? <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer sx={{ padding: 1 }} elevation={0}>
                    <WidgetLoader />
                </PaperContainer>
            </Grid> : dashboardSummary?.allOverSummary ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PaperContainer sx={{ padding: 1 }} elevation={0}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                            {(dashboardSummary?.allOverSummary?.totalEmploye || dashboardSummary?.allOverSummary?.totalEmploye == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalEmploye)}
                                title={"Total Employe"}
                                iconColor={"#3CD856"}
                                backgroundColor={"#DCFCE7"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalAdmin || dashboardSummary?.allOverSummary?.totalAdmin == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalAdmin)}
                                title={"Total Admin"}
                                iconColor={"#BF83FF"}
                                backgroundColor={"#F3E8FF"}
                                avtar={"/assets/icons/DashboardIcon1.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalReceptionist || dashboardSummary?.allOverSummary?.totalReceptionist == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalReceptionist)}
                                title={"Total Receptionist"}
                                iconColor={"#FA5A7D"}
                                backgroundColor={"#FFE2E5"}
                                avtar={"/assets/icons/DashboardIcon1.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalSite || dashboardSummary?.allOverSummary?.totalSite == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalSite)}
                                title={"Total Site"}
                                iconColor={"#FF947A"}
                                backgroundColor={"#FFF4DE"}
                                avtar={"/assets/icons/DashboardIcon3.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.approvalPendingSite || dashboardSummary?.allOverSummary?.approvalPendingSite == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.approvalPendingSite)}
                                title={"Total Approval Pending Site"}
                                iconColor={"#858585"}
                                backgroundColor={"#FFF4DE"}
                                avtar={"/assets/icons/DashboardIcon3.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.approvedSite || dashboardSummary?.allOverSummary?.approvedSite == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.approvedSite)}
                                title={"Total Approved Site"}
                                iconColor={"#FA5A7D"}
                                backgroundColor={"#FFE2E5"}
                                avtar={"/assets/icons/DashboardIcon3.png"}
                            /> : ""}
                            

                        </Grid>
                    </PaperContainer>
                </Grid>
            ) : <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer elevation={0}>
                    <WidgetLoader />
                </PaperContainer>
            </Grid>}
        </>
    )
}

export default Summary
