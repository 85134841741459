import React, { useState } from "react";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import swal from "sweetalert";
import { Image_BASE_URL } from "../../APiSetUp/axios";

const useStyles = makeStyles()((theme) => {
    return {
        dropText: {
            fontSize: "20px",
            fontWeight: 500,
            [theme.breakpoints.down("md")]: {
                fontSize: "16px",
            },
        },
        avatar: {
            height: "42px",
            width: "42px",
            objectFit: "cover",
            borderRadius: "50%",
        }
    };
});

const CommonFileDrop = ({ handleImageChange, sx, value, id, fileType, accept, isMultiple, handleImgClose, values, setFieldValue, handleSingleDelate }) => {
    const theme = useTheme();
    const { classes } = useStyles();
    const [dragging, setDragging] = useState(false);
    const [fileName, setFileName] = useState("");

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files;
        handleFileChange(file);
    };
    const handleFileInput = (e) => {
        const file = e.target.files;
        handleFileChange(file);
    };

    const handleFileChange = (files) => {
        const acceptedExtensions = accept.split(',').map(type => type.trim().split('/').pop());
        let invalidFiles = [];
        for (let i = 0; i < files.length; i++) {
            const fileExtension = files[i].name.split('.').pop();
            if (!acceptedExtensions.includes(fileExtension.toLowerCase())) {
                invalidFiles.push(files[i].name);
            } else if (files[i].size > 10 * 1024 * 1024) {
                invalidFiles.push(files[i].name + " exceeds the maximum file size of 10MB.");
            }
        }
        if (invalidFiles.length > 0) {
            swal("Error", `Invalid files: ${invalidFiles.join(", ")}`, "success");
        } else {
            handleImageChange(files, id);
            setFileName(files);
        }
    };

    return (
        <>
            <Box
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                sx={{ ...sx, position: "relative", border: `1px dashed #ccc`, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", cursor: "grab"}}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Typography
                        onClick={() => document.getElementById(`${id}-fileInput`).click()}
                        className={classes.dropText}
                        sx={{ color: theme.palette.primary.main, textDecoration: "underline" }}
                    >
                        Choose File{" "}
                    </Typography>{" "}
                    <Typography sx={{ color: "#828282" }} className={classes.dropText}>{" "}or Drop Here{" "}</Typography>
                </Box>

                <input
                    multiple={isMultiple ? true : false}
                    id={`${id}-fileInput`}
                    type="file"
                    accept={accept}
                    style={{ display: "none" }}
                    onChange={handleFileInput}
                />
            </Box>

            {isMultiple ? (value?.length > 0 ? (value?.map((img, i) => {
                return (
                    <Grid container key={i}>
                        <Grid item xs={9}
                            sx={{ backgroundColor: "#ECF4FF", borderRadius: "8px", padding: "12px", marginTop: "10px", }}
                        >
                            <Box display={"flex"} gap={1} alignItems={"center"}>
                                {fileType == "audio" ?
                                    <PlayCircleOutlineIcon color="primary" />
                                    : <img src={Image_BASE_URL + img} className={classes.avatar} alt="EmpProfile" />}
                                <Box flexGrow={1}>
                                    <Typography fontSize={"16px"} fontWeight={"400"}>
                                        {img}
                                    </Typography>
                                </Box>
                                <Box flexShrink={0}>
                                    <CloseIcon color="error" sx={{ cursor: "pointer", fontSize: "18px" }} onClick={() => handleImgClose(i, values || "", setFieldValue || "")} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                );
            })
            ) : ("")
            ) : value ? (
                <Grid container>
                    <Grid item xs={9} sx={{ backgroundColor: "#ECF4FF", borderRadius: "8px", padding: "12px", marginTop: "10px", }}>
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                            {fileType == "audio" ?
                                <PlayCircleOutlineIcon color="primary" />
                                : <img src={Image_BASE_URL + value} className={classes.avatar} alt="EmpProfile" />}
                            <Box flexGrow={1}>
                                <Typography fontSize={"16px"} fontWeight={"400"}>
                                    {value}
                                </Typography>
                            </Box>
                            <CloseIcon color="error" sx={{ cursor: "pointer", fontSize: "18px" }} onClick={handleSingleDelate} />
                        </Box>
                    </Grid>
                </Grid>
            ) : ("")}
        </>
    );
};
export default CommonFileDrop;
