import { Box, Chip, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonButton from "../Common/Button/CommonButton";
import dayjs from "dayjs";
import TextLabel from "../Common/Fields/TextLabel";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const ScheduleBhojan = ({
  data,
  onSubmit,
  handleChange,
  scheduleBhojanDate,
  error,
  santBhojanDate,
  setSantBhojanDate,
}) => {
  console.log("scheduleBhojanDate", santBhojanDate);

  return (
    <>
      {data?.multipleDate?.length === 0 && (
        <Grid
          container
          spacing={2}
          xs={12}
          md={12}
          lg={12}
          sm={12}
          pt={3}
          pb={0}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display={"flex"}
            justifyContent={"center"}
          >
            <Chip
              label={"અનુસૂચિ તારીખ ઉપલબ્ધ નથી"}
              style={{ width: "250px" }}
              color={"error"}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} xs={12} md={12} lg={12} sm={12} py={2} pl={2}>
        {data?.multipleDate?.map((date, index) => (
          <Grid item xs={6} sm={4} md={3} lg={3}>
            <Chip
              key={index}
              label={dayjs(date)?.format("DD/MM/YYYY")}
              clickable
              color={scheduleBhojanDate === date ? "primary" : "default"}
              onClick={() => handleChange(date)}
              style={{ width: "180px" }}
            />
          </Grid>
        ))}
        {scheduleBhojanDate && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div>
              <Typography variant="body2" fontWeight={700}>
                Schedule date:
              </Typography>
              <Typography variant="body1">
                {dayjs(scheduleBhojanDate)?.format("DD/MM/YY")}
              </Typography>
            </div>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
              <MobileDateTimePicker
                label=""
                format="DD/MM/YYYY hh:mm A"
                value={santBhojanDate}
                onChange={(newValue) => setSantBhojanDate(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!scheduleBhojanDate ? error?.scheduleDate : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CommonButton
              width={"280px"}
              text={"Schedule સંત ભોજન"}
              type="submit"
              onClick={() => onSubmit(data?._id, santBhojanDate)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ScheduleBhojan;
