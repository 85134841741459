import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  Table,
  TableRow,
  TableHead,
  TableContainer,
  Box,
  Grid,
  Switch,
  useTheme
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import CommonModal from "../../Components/Common/CommonModel";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { lightTheme } from "../../theme";
import { permissionStatus } from "../../Utils/enum";
import WidgetLoader from "../../Components/Common/widgetLoader";
import Swal from "sweetalert2";
import CustomPagination from "../../Components/Common/CustomPagination";
import AddSantNamavli from "../../Components/SantNamavli";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
     color: theme.palette.primary.main,
    fontFamily: "Poppins",
    padding: '8px 24px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: '8px 24px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "8px",
      backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
      color: lightTheme.palette.primary.main,
      cursor: "pointer",
    },
    viewBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightSuccess.main,
      backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightRed.main,
      backgroundColor: lightTheme.palette.bgLightExtraRed.main,
      cursor: "pointer",
    },
  };
});

const SantNamavli = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme()
  const { OnUpdateError, toggleLoader, menuList } = useAppContext();

  //States
  const [model, setModel] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [santNamavliDetails, setSantNamavliDetails] = useState([]);
  const [id, setId] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [permissions, setPermissions] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  //Validation
  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!data?.name) {
      formIsValid = false;
      errors["name"] = "Please enter santName.";
    }
    if (
      Number(data?.priority) <= 0 ||
      santNamavliDetails?.count + 1 < Number(data?.priority)
    ) {
      formIsValid = false;
      errors["priority"] =
        santNamavliDetails?.count + 1 < Number(data?.priority)
          ? `Please enter priority below ${santNamavliDetails?.count + 2}.`
          : "Please enter priority.";
    }
    setError(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const _getSantNamavli = () => {
    setLoading(true);
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
      isAll: true,
    };
    axios
      .post(`/getSantnamavaliDetails`, body)
      .then((res) => {
        if (res?.data?.data) setSantNamavliDetails(res?.data?.data);
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => setLoading(false));
  };

  const _addUpdateSantName = () => {
    if (handleValidation()) {
      toggleLoader();
      const {
        isActive,
        isBlock,
        isDeleted,
        createdBy,
        updatedBy,
        createdAt,
        updatedAt,
        __v,
        ...santData
      } = data;
      const requestData = isEdit ? { ...santData, _id: id } : santData;
      axios({
        method: isEdit ? "put" : "post",
        url: isEdit ? "updateSantnamavali" : "addSantnamavali",
        data: requestData,
      })
        .then((res) => {
          swal("Success", res?.data?.message, "success");
          _getSantNamavli();
          toggleLoader();
          handleClear();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  const _activeHandler = (id, value) => {
    toggleLoader();
    const {
      isBlock,
      isDeleted,
      createdBy,
      updatedBy,
      createdAt,
      updatedAt,
      __v,
      ...santData
    } = data;
    const requestData = { ...santData, _id: id, isActive: value };
    axios({
      method: "put",
      url: "updateSantnamavali",
      data: requestData,
    })
      .then((res) => {
        swal("Success", res?.data?.message, "success");
        _getSantNamavli();
        toggleLoader();
        handleClear();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _deleteSantNamavli = async (id) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "શું તમે ખરેખર સંત નામ કાઢી નાખવા માંગો છો ?",
      showCancelButton: true,
      confirmButtonColor: '#ff0000 !important',
      iconColor: "#FF0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        axios
          .delete(`deleteSantnamavali/${id}`)
          .then((res) => {
            if (res?.data?.data) {
              swal("Success", res?.data?.message, "success");
              _getSantNamavli();
            }
          })
          .catch((err) => {
            OnUpdateError(err.data.message);
          })
          .finally(() => {
            toggleLoader();
          });
      }
    });
  };

  const handleClear = () => {
    setModel(false);
    setData({});
    setError({});
    setIsEdit(false);
  };

  useEffect(() => {
    _getSantNamavli();
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create)
          ? true
          : false,
        update: menuPermissions.includes(permissionStatus.update)
          ? true
          : false,
        delete: menuPermissions.includes(permissionStatus.delete)
          ? true
          : false,
      });
    }
  }, [menuList, location]);
  return (
    <>
      <PaperContainer elevation={0} square={false}>
        <Grid container>
          <Grid item xs={12}>
            <TableHeading
              title="સંત નામાવલી"
              buttonText={permissions?.create ? `Add સંત નામાવલી` : ""}
              onClick={() => setModel(true)}
              handleSearch={(value) => {
                setPage(1);
                setSearch(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TableContainer>
              {!loading ? (
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className={classes.paddedRow}>
                        No.
                      </StyledTableCell>
                      <StyledTableCell align="center">સંત નામ</StyledTableCell>
                      <StyledTableCell align="center">પ્રાથમિકતા</StyledTableCell>
                      <StyledTableCell align="center">Active/Inactive</StyledTableCell>
                      <StyledTableCell align="right">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {santNamavliDetails?.response?.length > 0 ? (
                      santNamavliDetails?.response?.map((row, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell className={classes.paddedRow}>
                              {(index % rowsPerPage) +
                                1 +
                                (page - 1) * rowsPerPage}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.name}</StyledTableCell>
                            <StyledTableCell align="center">
                              {row.priority || "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Switch
                                checked={row?.isActive}
                                onChange={(e) =>
                                  _activeHandler(row._id, e?.target?.checked)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <Box
                                display={"flex"}
                                gap={1}
                                justifyContent={"right"}
                              >
                                {permissions?.update && (
                                  <Assets
                                    className={classes.writeBox}
                                    src={"/assets/icons/write.svg"}
                                    absolutePath={true}
                                    onClick={() => {
                                      setData(row);
                                      setIsEdit(true);
                                      setId(row._id);
                                      setModel(true);
                                    }}
                                  />
                                )}
                                {permissions?.delete && (
                                  <Assets
                                    className={classes.deleteBox}
                                    src={"/assets/icons/delete.svg"}
                                    absolutePath={true}
                                    onClick={() => _deleteSantNamavli(row?._id)}
                                  />
                                )}
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12}>
                          {" "}
                          <DataNotFound
                            icon={
                              <ErrorOutlineIcon
                                color="primary"
                                style={{ fontSize: "3rem" }}
                              />
                            }
                            elevation={0}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              ) : (
                <WidgetLoader />
              )}
            </TableContainer>
          </Grid>
          {santNamavliDetails?.count > 0 && (
            <Grid item xs={12}>
              <CustomPagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={Math.ceil(santNamavliDetails?.count / rowsPerPage)}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          )}
        </Grid>
      </PaperContainer>

      {model && (
        <CommonModal
          open={model}
          onClose={handleClear}
          title={`${isEdit ? "Update" : "Add"} સંત નામાવલી`}
          content={
            <AddSantNamavli
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              onSubmit={_addUpdateSantName}
              isEdit={isEdit}
              santNamavliDetails={santNamavliDetails}
            />
          }
        />
      )}
    </>
  );
};

export default SantNamavli;
