import { Button, Typography, Box, useTheme } from "@mui/material";
import { Image_BASE_URL } from "../../APiSetUp/axios";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Assets from "./ImageContainer";
import { lightTheme } from "../../theme";
import { makeStyles } from "tss-react/mui";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const useStyles = makeStyles()((theme) => {
    return {
        clearBox: {
            borderRadius: "6px",
            padding: "4px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
        },
    };
});

const FileUpload = ({ handleFileChange, selectedFile, OnDelate, text, acceptFile, multiple = false }) => {
    const theme = useTheme()
    const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png"];
    const { classes } = useStyles();
    const fileExtension = selectedFile?.split('.')?.pop()?.toLowerCase();
    const getThumbnail = () => {
        let thumb;
        if (fileExtension === 'pdf') {
            thumb = <PictureAsPdfIcon fontSize="large" color="error" />
        } else if (fileExtension === 'mp3') {
            thumb = <audio class='audioPlayer' controls style={{ height: '44px' }}>  <source src={`${Image_BASE_URL}${selectedFile}`} type="audio/mpeg" /></audio>
        } else if (fileExtension === 'jpeg' || fileExtension === 'gif' || fileExtension === 'jpg' || fileExtension === 'png') {
            thumb = <img src={`${Image_BASE_URL}${selectedFile}`} alt="Selected" style={{ height: '50px', maxWidth: '150px' }} />
        } else {
            thumb = <NoteAddIcon fontSize="large" color="primary" />
        }
        return thumb
    }

    return (
        <Box p={1} border="1px dashed #ccc" borderRadius={2} textAlign="center" display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'} flexWrap={'wrap'}>
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}  >
                {text ? text : "Upload"}
                <VisuallyHiddenInput type="file" onChange={handleFileChange} onClick={(event) => {
                    event.target.value = null
                }} accept={acceptFile} multiple={multiple} />
            </Button>
            {selectedFile && (
                <Box width={'50%'} display={'flex'} alignItems={'center'} gap={2} textAlign='end' justifyContent={'flex-end'} flexWrap={'wrap'}>
                    <Typography variant="body2">
                        {selectedFile?.split("/")[2]}
                    </Typography>
                    {getThumbnail()}
                    {OnDelate && <Box display={"flex"} justifyContent={"end"} gap={1} onClick={OnDelate}>
                        <CloseOutlinedIcon sx={{ color: lightTheme.palette.error.main, fontSize: "25px" }} className={classes.clearBox} />
                    </Box>}
                </Box>
            )}
        </Box>
    )
}

export default FileUpload