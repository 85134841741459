import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Link,
  Svg,
  Path,
  Image
} from "@react-pdf/renderer";

import NotoSansGujarati from "../../fonts/NotoSansGujarati-Regular.ttf"; // Update with the path to your font
import dayjs from "dayjs";

// Register the font
Font.register({
  family: "NotoSansGujarati",
  src: NotoSansGujarati,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 10,
    fontSize: 14,
    fontFamily: "NotoSansGujarati",
  },
  container: {
    flex: 1,
    border: "1px solid lightGray",
    borderRadius: 1,
    padding: 10,
    marginBottom: 10,
  },
  title: {
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    borderStyle: "double",
    borderColor: "gray",
    borderRadius: 2,
    padding: 5,
    fontWeight: 700,
    display: "inline-block",
    marginBottom: 10,
    textAlign: "center",
  },
  inputContainer: {
    flexDirection: "row",
    marginBottom: 5,
  },
  inputLabel: {
    width: "25%",
    fontWeight: "bold",
  },
  inputValue: {
    width: "75%",
    borderBottom: "1px solid black",
  },
  text: {
    margin: 5,
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
  },
  contactContainer: {
    display: "flex",
    flexDirection: "row",
  },
  date: {
    border: 1,
    borderTopLeftRadius: 5,
    height: "35px",
    width: "33%",
    padding: 1,
  },
  day: {
    border: 1,
    height: "35px",
    width: "33%",
    padding: 1,
  },
  time: {
    border: 1,
    borderTopRightRadius: 5,
    height: "35px",
    width: "33%",
    padding: 1,
  },
  name: {
    border: 1,
    height: "35px",
    width: "66%",
    padding: 1,
  },
  mobile: {
    border: 1,
    height: "35px",
    width: "33%",
    padding: 1,
  },
  address: {
    border: 1,
    height: "80px",
    width: "99%",
    padding: 1,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },

  textBox: {
    height: "35px",
    width: "99%",
    border: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },

  leftBox: {
    height: "35px",
    width: "33%",
    border: 1,
  },
  rightBox: {
    height: "35px",
    width: "33%",
    border: 1,
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  textBox2: {
    height: "35px",
    width: "99%",
    border: 1,
    display: "flex",
    justifyContent: "center",
  },
  smallBox: {
    height: "35px",
    width: "33%",
    border: 1,
  },
  containerBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  leftsmallBox: {
    height: "35px",
    width: "33%",
    border: 1,
    borderBottomLeftRadius: 5,
  },
  rightsmallBox: {
    height: "35px",
    width: "33%",
    border: 1,
    borderBottomRightRadius: 5,
  },
  lastLine: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  textBox3: {
    height: "35px",
    width: "99%",
    border: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
  },
  textBox4: {
    height: "35px",
    width: "99%",
    border: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "8px",
  },
  linkContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  linkText: {
    color: "blue",
  },
  icon: {
    width: 20,
    height:20,
    marginLeft: 5,
  },
});

const PdfFormat = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        <Text style={styles.title}>|| શ્રી સ્વામિનારાયણો વિજયતે ||</Text>
        <Text style={styles.subtitle}>સંત ભોજન</Text>

        {/* Box 1 */}
        <View>
          <View style={styles.timeContainer}>
            <View style={styles.date}>
              <Text style={styles.text}>
                તારીખ: {dayjs(data?.date).format("DD/MM/YYYY")}
              </Text>
            </View>
            <View style={styles.day}>
              <Text style={styles.text}>વાર: {data?.day}</Text>
            </View>
            <View style={styles.time}>
              <Text style={styles.text}>
                સમય: {dayjs(data?.time).format("hh:mm A")}
              </Text>
            </View>
          </View>

          <View style={styles.contactContainer}>
            <View style={styles.name}>
              <Text style={styles.text}>
                નામ: {data?.name} {data?.surname}
              </Text>
            </View>
            <View style={styles.mobile}>
              <Text style={styles.text}>મો. નં.: {data?.phoneNumber}</Text>
            </View>
          </View>

          <View style={styles.address}>
            <Text style={styles.text}>
              સરનામું: {data?.houseNo}, {data?.societyName}, {data?.landmark},{" "}
              {data?.area}
            </Text>
            <View style={styles.linkContainer}>
              <Text style={styles.text}>
                એડ્રેસ લિંક:{" "}
                <Link style={styles.linkText} src={data?.description}>
                   અહીં ક્લિક કરો <br/>
                  <Image
                style={styles.icon}
                src="/assets/image/google-maps.png" // Corrected path
              />
                </Link>
              </Text>
            </View>
          </View>
        </View>

        {/* Box 2 */}
        <View>
          <View style={styles.textBox}>
            <Text style={[styles.text, { textAlign: "center" }]}>
              મહા પૂજા માટે પધારનાર પૂ. સંતો (સવારે ૯:૦૦ વાગ્યે મંદિરેથી
              નીકળવું.)
            </Text>
          </View>
          <View style={styles.mainBox}>
            {data?.mahapoojaSantDetails.map((ele, index) => (
              <View
                key={index}
                style={index % 2 === 0 ? styles.rightBox : styles.leftBox}
              >
                <Text style={[styles.text, { textAlign: "center" }]}>
                  {ele?.name}
                </Text>
              </View>
            ))}
          </View>
        </View>

        {/* Box 3 */}
        <View>
          <View style={styles.textBox}>
            <Text style={[styles.text, { textAlign: "center" }]}>
              થાળ માટે પધારનાર પૂ. સંતો (સવારે ૧૦:૦૦ વાગ્યે મંદિરેથી
              નીકળવું.)
            </Text>
          </View>
          <View style={styles.mainBox}>
            {data?.thalSantDetails.map((ele, index) => (
              <View
                key={index}
                style={index % 2 === 0 ? styles.rightBox : styles.leftBox}
              >
                <Text style={[styles.text, { textAlign: "center" }]}>
                  {ele?.name}
                </Text>
              </View>
            ))}
          </View>
        </View>

        {/* Box 4 */}
        <View>
          <View style={styles.textBox3}>
            <Text style={[styles.text, { textAlign: "center" }]}>
              પધારનાર પૂ. સંતો (સવારે ૧૧:૧૫ વાગ્યે મંદિરેથી નીકળવું.)
            </Text>
          </View>
          {data?.vehicleDetails.map((e, i) => (
            <View key={i} style={{ marginBottom: "8px" }}>
              <View style={styles.textBox2}>
                <Text style={[styles.text, { textAlign: "start" }]}>
                  ગાડી - {i + 1}: .....
                </Text>
              </View>
              <View style={styles.containerBox}>
                {e?.vehicleSantNameDetails.map((element, inx) => (
                  <View key={inx} style={styles.smallBox}>
                    <Text style={[styles.text, { textAlign: "center" }]}>
                      {element?.name}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
        <View>
          <View style={styles.textBox4}>
            <Text style={[styles.text, { textAlign: "center" }]}>
              કુલ સંતો - {data?.count}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default PdfFormat;
