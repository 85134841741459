import React from 'react'

const DesignationIcon = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 20.61C13.46 20.61 11.39 18.545 11.39 16C11.39 13.46 13.455 11.39 16 11.39C18.545 11.39 20.61 13.455 20.61 16C20.61 18.54 18.54 20.61 16 20.61ZM16 12.39C14.01 12.39 12.39 14.01 12.39 16C12.39 17.99 14.01 19.61 16 19.61C17.99 19.61 19.61 17.99 19.61 16C19.61 14.01 17.99 12.39 16 12.39ZM16 11.055C15.725 11.055 15.5 10.83 15.5 10.555V6.63998C15.5 6.36498 15.725 6.13998 16 6.13998C16.275 6.13998 16.5 6.36498 16.5 6.63998V10.555C16.5 10.83 16.275 11.055 16 11.055Z" fill={props.color}/>
    <path d="M16 7.14C14.185 7.14 12.71 5.665 12.71 3.85C12.71 2.035 14.185 0.559998 16 0.559998C17.815 0.559998 19.29 2.035 19.29 3.85C19.29 5.665 17.815 7.14 16 7.14ZM16 1.56C15.3926 1.56 14.8101 1.80126 14.3807 2.23072C13.9512 2.66018 13.71 3.24265 13.71 3.85C13.71 4.45734 13.9512 5.03981 14.3807 5.46927C14.8101 5.89873 15.3926 6.14 16 6.14C17.26 6.14 18.29 5.11 18.29 3.85C18.29 2.59 17.265 1.56 16 1.56ZM16 25.86C15.725 25.86 15.5 25.635 15.5 25.36V21.445C15.5 21.17 15.725 20.945 16 20.945C16.275 20.945 16.5 21.17 16.5 21.445V25.36C16.5 25.635 16.275 25.86 16 25.86Z" fill={props.color}/>
    <path d="M16 31.44C14.185 31.44 12.71 29.965 12.71 28.15C12.71 26.335 14.185 24.86 16 24.86C17.815 24.86 19.29 26.335 19.29 28.15C19.29 29.965 17.815 31.44 16 31.44ZM16 25.86C15.6964 25.8555 15.395 25.9113 15.1133 26.0244C14.8315 26.1374 14.575 26.3053 14.3588 26.5184C14.1425 26.7314 13.9708 26.9853 13.8535 27.2653C13.7363 27.5454 13.6759 27.8459 13.6759 28.1495C13.6758 28.4531 13.7362 28.7536 13.8533 29.0337C13.9705 29.3137 14.1422 29.5677 14.3584 29.7807C14.5746 29.9938 14.8311 30.1618 15.1128 30.2749C15.3945 30.388 15.6959 30.4439 15.9995 30.4395C16.6009 30.4307 17.1747 30.1856 17.5969 29.7572C18.0191 29.3288 18.2558 28.7515 18.2559 28.15C18.2559 27.5485 18.0194 26.9711 17.5972 26.5426C17.1751 26.1141 16.6014 25.8689 16 25.86ZM20.345 13.22C20.195 13.22 20.045 13.15 19.945 13.02C19.78 12.8 19.825 12.485 20.045 12.32L23.17 9.96498C23.2223 9.9252 23.2821 9.89621 23.3458 9.87969C23.4094 9.86318 23.4757 9.85946 23.5408 9.86876C23.606 9.87807 23.6686 9.9002 23.7251 9.93388C23.7816 9.96756 23.8308 10.0121 23.87 10.065C24.035 10.285 23.99 10.6 23.77 10.765L20.645 13.12C20.555 13.19 20.45 13.22 20.345 13.22Z" fill={props.color}/>
    <path d="M25.7 11.97C24.7 11.97 23.715 11.52 23.07 10.665C22.8081 10.3206 22.6174 9.92739 22.5093 9.50843C22.4011 9.08947 22.3775 8.65316 22.44 8.22499C22.4984 7.79694 22.6412 7.38475 22.8601 7.01226C23.079 6.63978 23.3695 6.31441 23.715 6.05499C24.4119 5.52945 25.289 5.30218 26.1535 5.42313C27.018 5.54408 27.799 6.00335 28.325 6.69999C28.5872 7.04426 28.778 7.43743 28.8861 7.85643C28.9943 8.27543 29.0177 8.71182 28.955 9.13999C28.8965 9.56804 28.7537 9.98023 28.5349 10.3527C28.316 10.7252 28.0254 11.0506 27.68 11.31C27.1089 11.7387 26.4141 11.9704 25.7 11.97ZM25.695 6.39499C25.215 6.39499 24.73 6.54499 24.32 6.85499C23.83 7.22499 23.515 7.75999 23.43 8.36499C23.345 8.96999 23.5 9.57499 23.87 10.06C24.2364 10.5448 24.7801 10.8644 25.3819 10.9488C25.9837 11.0331 26.5944 10.8753 27.08 10.51C27.57 10.14 27.885 9.60499 27.97 8.99999C28.055 8.39499 27.9 7.78999 27.53 7.30499C27.3157 7.02134 27.0383 6.79144 26.7198 6.6335C26.4014 6.47556 26.0505 6.3939 25.695 6.39499ZM8.52998 22.135C8.37998 22.135 8.22998 22.065 8.12998 21.935C7.96498 21.715 8.00998 21.4 8.22998 21.235L11.355 18.88C11.4074 18.8402 11.4671 18.8112 11.5308 18.7947C11.5944 18.7782 11.6608 18.7745 11.7259 18.7838C11.791 18.7931 11.8536 18.8152 11.9101 18.8489C11.9666 18.8826 12.0158 18.9271 12.055 18.98C12.22 19.2 12.175 19.515 11.955 19.68L8.82998 22.035C8.73998 22.105 8.63498 22.135 8.52998 22.135Z" fill={props.color}/>
    <path d="M6.30998 26.61C6.15498 26.61 5.99998 26.6 5.84498 26.58C4.98056 26.4579 4.20002 25.9975 3.67498 25.3C3.14945 24.6031 2.92218 23.7259 3.04313 22.8615C3.16408 21.997 3.62335 21.216 4.31998 20.69C5.01998 20.16 5.88999 19.94 6.75998 20.06C7.62998 20.18 8.39999 20.635 8.92999 21.335C9.45998 22.035 9.68499 22.9 9.55999 23.775C9.50152 24.2031 9.35873 24.6152 9.13987 24.9877C8.92102 25.3602 8.63045 25.6856 8.28498 25.945C7.71697 26.3765 7.02328 26.61 6.30998 26.61ZM4.92498 21.49C4.4402 21.8564 4.12056 22.4002 4.03619 23.002C3.95183 23.6037 4.10963 24.2144 4.47498 24.7C4.84498 25.19 5.37998 25.505 5.98498 25.59C6.58998 25.675 7.19498 25.52 7.67998 25.15C8.16498 24.78 8.48498 24.245 8.56998 23.64C8.65498 23.035 8.49998 22.43 8.12998 21.945C7.94965 21.7042 7.72333 21.5015 7.46414 21.3487C7.20495 21.196 6.91802 21.0961 6.61998 21.055C6.01498 20.965 5.40999 21.12 4.92498 21.49ZM11.655 13.22C11.55 13.22 11.445 13.185 11.355 13.12L8.22998 10.765C8.00998 10.6 7.96498 10.285 8.12998 10.065C8.29498 9.84501 8.60998 9.80001 8.82998 9.96501L11.955 12.32C12.175 12.485 12.22 12.8 12.055 13.02C11.955 13.15 11.805 13.22 11.655 13.22Z" fill={props.color}/>
    <path d="M6.29997 11.97C5.60997 11.97 4.91497 11.755 4.31997 11.305C3.61997 10.775 3.16497 10.005 3.04497 9.13498C2.92497 8.26498 3.14997 7.39998 3.67497 6.69498C4.20092 5.99835 4.98198 5.53908 5.84645 5.41813C6.71091 5.29718 7.58802 5.52445 8.28497 6.04998C8.98497 6.57998 9.43997 7.34998 9.55997 8.21998C9.67997 9.08998 9.45497 9.95498 8.92997 10.66C8.62358 11.0677 8.22634 11.3984 7.76981 11.6258C7.31327 11.8532 6.81001 11.9711 6.29997 11.97ZM6.30497 6.39498C5.60997 6.39498 4.92497 6.70998 4.47497 7.30498C4.10497 7.78998 3.94997 8.39498 4.03497 8.99998C4.11997 9.60498 4.43497 10.145 4.92497 10.51C5.4106 10.8752 6.02125 11.0329 6.623 10.9486C7.22475 10.8642 7.76849 10.5447 8.13497 10.06C8.50497 9.56998 8.65997 8.96998 8.57497 8.36498C8.48997 7.75998 8.17497 7.21998 7.68497 6.85498C7.28685 6.55639 6.80262 6.39498 6.30497 6.39498ZM23.47 22.135C23.365 22.135 23.26 22.1 23.17 22.035L20.045 19.68C19.825 19.515 19.78 19.2 19.945 18.98C20.11 18.76 20.425 18.715 20.645 18.88L23.77 21.235C23.99 21.4 24.035 21.715 23.87 21.935C23.77 22.07 23.62 22.135 23.47 22.135Z" fill={props.color}/>
    <path d="M25.69 26.61C24.98 26.61 24.29 26.38 23.715 25.945C23.015 25.415 22.56 24.645 22.44 23.775C22.32 22.905 22.545 22.04 23.07 21.335C23.6 20.635 24.37 20.18 25.24 20.06C26.11 19.94 26.975 20.165 27.68 20.69C29.13 21.785 29.415 23.85 28.325 25.3C28.0656 25.6454 27.7402 25.936 27.3677 26.1549C26.9952 26.3737 26.583 26.5165 26.155 26.575C26 26.6 25.845 26.61 25.69 26.61ZM25.7 21.025C25.345 21.0225 24.9943 21.1036 24.6764 21.2617C24.3585 21.4198 24.0823 21.6504 23.87 21.935C23.5 22.425 23.345 23.025 23.43 23.63C23.515 24.235 23.83 24.775 24.32 25.14C24.81 25.51 25.41 25.665 26.015 25.58C26.62 25.495 27.16 25.18 27.525 24.69C27.8902 24.2044 28.0479 23.5937 27.9636 22.992C27.8792 22.3902 27.5597 21.8465 27.075 21.48C26.675 21.185 26.195 21.025 25.7 21.025Z" fill={props.color}/>
    </svg>
    
  )
}

export default DesignationIcon