import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, Box, Grid, useTheme, TableContainer, Tabs, Tab } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { lightTheme } from "../../theme";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import CommonModal from "../../Components/Common/CommonModel";
import swal from "sweetalert";
import { Roles, permissionStatus, siteStatus, userType } from "../../Utils/enum";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Swal from "sweetalert2";
import WidgetLoader from "../../Components/Common/widgetLoader";
import CustomPagination from "../../Components/Common/CustomPagination";
import AddSite from "../../Components/Site";
import ViewSite from "../../Components/Site/view";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        whiteSpace: "nowrap",
        padding: 8
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: "8px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => {
    return {
        paddedRow: {
            padding: "15px 10px",
        },
        writeBox: {
            borderRadius: "6px",
            padding: "6px",
            backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
            color: lightTheme.palette.primary.main,
            cursor: "pointer",
            height: '26px'
        },
        viewBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightSuccess.main,
            backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
            cursor: "pointer",
            height: '26px'
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
            height: '26px'
        },
    };
});
const siteStatusConfig = [
    {
        statusName: "Approval pending",
        statusId: 0,
    },
    {
        statusName: "Approved By SuperAdmin",
        statusId: 1,
    },
    {
        statusName: "Rejected By SuperAdmin",
        statusId: 2,
    },
    {
        statusName: "Approved By Admin",
        statusId: 3,
    },
    {
        statusName: "Rejected By Admin",
        statusId: 4,
    },
];

const statusColors = {
    0: "#FDCF71",
    1: "#2DB70E",
    2: "#FF7474",
    3: "#2DB70E",
    4: "#FF7474",
};

const SiteTable = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();
    const location = useLocation();

    //States
    const [siteData, setSiteData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false)
    const [viewModel, setViewModel] = useState(false);
    const [siteImages, setSiteImages] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleClear = () => {
        setViewModel(false);
    };

    const _getSite = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || "",
            status: 0
        };
        await axios.post(`/site`, body)
            .then((res) => {
                if (res?.data?.data) {
                    setSiteData(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    useEffect(() => {
        _getSite();
    }, [page, rowsPerPage, search]);

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer elevation={0} square={false}>
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12}>
                            <TableHeading
                                title={"Site list"}
                                handleSearch={(value) => { setPage(1); setSearch(value); }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer>
                                {!loading ? (
                                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                                                <StyledTableCell>Site Name</StyledTableCell>
                                                <StyledTableCell>Site Type</StyledTableCell>
                                                <StyledTableCell >Location</StyledTableCell>
                                                <StyledTableCell>Latitude</StyledTableCell>
                                                <StyledTableCell>Longitude</StyledTableCell>
                                                <StyledTableCell>Description</StyledTableCell>
                                                <StyledTableCell align="center" >Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {siteData?.response?.length > 0 ? siteData?.response?.map((row, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className={classes.paddedRow}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">{row?.siteName}</StyledTableCell>
                                                        <StyledTableCell>{row?.siteTypesDetails?.siteType}</StyledTableCell>
                                                        <StyledTableCell>{row?.location}</StyledTableCell>
                                                        <StyledTableCell>{row.latitude}</StyledTableCell>
                                                        <StyledTableCell>{row.longitude}</StyledTableCell>
                                                        <StyledTableCell>{row?.description}</StyledTableCell>
                                                        <StyledTableCell >
                                                            <Box display={"flex"} gap={1} justifyContent={"center"}>
                                                                <Assets className={classes.viewBox} src={"/assets/icons/eye.svg"} absolutePath={true} onClick={() => { setViewModel(true); setSiteImages(row?.siteImages) }} />
                                                            </Box>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            }) :
                                                <TableRow>
                                                    <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} /></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <WidgetLoader />
                                )}
                            </TableContainer>
                        </Grid>
                    </Grid>
                    {siteData?.count > 0 &&
                        <Grid item xs={12}>
                            <CustomPagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={Math.ceil(siteData?.count / rowsPerPage)}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />

                        </Grid>}
                </PaperContainer>
            </Grid>

            {viewModel && (
                <CommonModal
                    open={viewModel}
                    onClose={handleClear}
                    title={`Site Deatis`}
                    maxWidth={'lg'}
                    content={<ViewSite siteImages={siteImages} />}
                />

            )}
        </>
    )
}

export default SiteTable