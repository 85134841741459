import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from './Pages/Login';
import Loader from './Components/Loader';
import { ThemeProvider } from "@mui/material";
import { lightTheme } from "./theme";
import Dashboard from "./Pages/Dashboard";
import Layout from "./Components/Layout";
import { ProtectedRoutes } from "./Routes/ProtectedRoutes";
import OTPverification from "./Pages/OTPverification";
import ForgetPassword from "./Pages/ForgetPassword";
import Resetpassword from "./Pages/Resetpassword";
import { useAppContext } from "./Context/context";
import SantNamavli from "./Pages/SantNamavli";
import SantBhojan from "./Pages/SantBhojan";
import SantBhojanPublic from "./Pages/SantBhojanPublic";
import { Roles, userType } from "./Utils/enum";

function App() {
  const { user } = useAppContext();

  console.log('user', user?.userType === Roles?.SuperAdmin)
  return (
    <BrowserRouter basename={"/"}>
      <ThemeProvider theme={lightTheme}>
        <Loader />
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/forgot-password" element={<ForgetPassword />} /> */}
            <Route path="/otp-verification" element={<OTPverification />} />
            <Route path="/reset-password" element={<Resetpassword />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<SantBhojan />} />
              <Route path="/sant-namavli" element={<SantNamavli />} />
              {/* <Route path="/santBhojan" element={<SantBhojanPublic />} /> */}
            </Route>
          </Routes>
        </Layout>
        <Routes>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;