import React, { useEffect, useState } from "react";
import { Box, Grid, InputLabel, useTheme } from "@mui/material";
import TextLabel from "../Common/Fields/TextLabel";
import CommonTextField from "../Common/Fields/TextField";
import CommonButton from "../Common/Button/CommonButton";
import { Regex } from "../../Utils/regex";
import dayjs from "dayjs";
import DatePicker from "react-multi-date-picker";

const AddSantBhojan = ({
  data,
  setData,
  error,
  handleChange,
  isEdit,
  onSubmit,
  countries,
  setSelectedCountry,
}) => {
  const theme = useTheme();

  console.log(data?.multipleDate[0], "multipleDate");
  useEffect(() => {
    const defaultCountry = "India";
    const defaultCountryObj = countries?.response?.find(
      (country) => country.name === defaultCountry
    );
    if (defaultCountryObj) {
      setSelectedCountry(defaultCountry);
    }
  }, [countries, setSelectedCountry]);

  return (
    <Box sx={{ backgroundColor: theme?.palette?.bgDefaultPrimaryLight?.main }}>
      <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2} mt={0}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <CommonTextField
            fontWeight={400}
            text={"નામ"}
            placeholder={"Enter નામ"}
            type="text"
            name="name"
            value={data?.name}
            onChange={(e) => handleChange(e, false)}
          />
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.name ? error?.name : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <CommonTextField
            fontWeight={400}
            text={"અટક"}
            placeholder={"Enter અટક"}
            type="text"
            name="surname"
            value={data?.surname}
            onChange={(e) => handleChange(e, false)}
          />
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.surname ? error?.surname : ""}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <CommonTextField
            fontWeight={400}
            text={"મોબાઈલ.નં"}
            placeholder={"Enter મોબાઈલ.નં"}
            type="number"
            name="phoneNumber"
            value={data?.phoneNumber}
            onChange={(e) => handleChange(e, false)}
          />
          {error?.phoneNumber &&
            !data?.phoneNumber?.toString()?.match(Regex.mobileNumberRegex) && (
              <TextLabel
                fontSize={"12px"}
                color={"red"}
                title={error?.phoneNumber}
              />
            )}
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box
            mt={0.5}
            // mb={1}
            display="flex"
            fontSize="12px"
            flexDirection={"row"}
          >
            <InputLabel
              sx={{
                marginRight: "3px",
                fontWeight: 400,
                fontSize: "14px",
                color: " #151D48",
                padding: "3px",
              }}
            >
              {" "}
              તારીખ
            </InputLabel>
          </Box>
          {/* <CommonTextField
                        fontWeight={400}
                        text={'તારીખ'}
                        type='date'
                        name='date'
                        value={dayjs(data.date).format("YYYY-MM-DD")}
                        onChange={(e) => handleChange(e, false)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.date ? error?.date : ""} /> */}
          <DatePicker
            multiple
            value={data?.multipleDate}
            onChange={(e, newValue) => {
              setData({ ...data, multipleDate: e });
            }}
            format="DD/MM/YYYY"
            className="green"
            placeholder="Select Dates"
            inputClass="rmdp-input"
            containerStyle={{ width: "100%" }}
            editable={false}
          />

          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={data?.multipleDate?.length == 0 ? error?.multipleDate : ""}
          />
        </Grid>

        {/* <Grid item xs={12} sm={2} md={2} lg={2}>
                    <CommonTextField fontWeight={400} text={'વાર'} placeholder={'વાર'} type='text' name='day' value={data?.day} />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.day ? error?.day : ""} />
                </Grid> */}

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <CommonTextField
            fontWeight={400}
            text={"ઘર/મક।ન નંબર."}
            placeholder={"Enter ઘર/મક।ન નંબર."}
            type="text"
            name="houseNo"
            value={data?.houseNo}
            onChange={(e) => handleChange(e, false)}
            rows={1}
          />
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.houseNo ? error?.houseNo : ""}
          />
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9}>
          <CommonTextField
            fontWeight={400}
            text={"સોસાયટી/એપાર્ટમેન્ટ નું નામ"}
            placeholder={"Enter સોસાયટી/એપાર્ટમેન્ટ નું નામ"}
            type="text"
            name="societyName"
            value={data?.societyName}
            onChange={(e) => handleChange(e, false)}
            rows={1}
          />
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.societyName ? error?.societyName : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <CommonTextField
            fontWeight={400}
            text={"સ્થાન દ્વારા નજીક"}
            placeholder={"Enter સ્થાન દ્વારા નજીક"}
            type="text"
            name="landmark"
            value={data?.landmark}
            onChange={(e) => handleChange(e, false)}
            rows={1}
          />
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.landmark ? error?.landmark : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <CommonTextField
            fontWeight={400}
            text={"વિસ્તાર"}
            placeholder={"Enter વિસ્તાર"}
            type="text"
            name="area"
            value={data?.area}
            onChange={(e) => handleChange(e, false)}
            rows={1}
          />
          <TextLabel
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.area ? error?.area : ""}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CommonTextField
            fontWeight={400}
            text={"મંડળનું નામ"}
            placeholder={"Enter મંડળનું નામ"}
            type="text"
            name="mandal"
            value={data?.mandal}
            onChange={(e) => handleChange(e, false)}
            multiline
            rows={1}
          />
          <TextLabel
            style={{ marginTop: "14px" }}
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.mandal ? error?.mandal : ""}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CommonTextField
            fontWeight={400}
            text={"ક્ષેત્ર નામ"}
            placeholder={"Enter ક્ષેત્ર નામ"}
            type="text"
            name="xetra"
            value={data?.xetra}
            onChange={(e) => handleChange(e, false)}
            multiline
            rows={1}
          />
          <TextLabel
            style={{ marginTop: "14px" }}
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.xetra ? error?.xetra : ""}
          />
        </Grid>
        
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CommonTextField
            fontWeight={400}
            text={"નોંધ કયા નિમિતે"}
            placeholder={"Enter નોંધ કયા નિમિતે"}
            type="text"
            name="note"
            value={data?.note}
            onChange={(e) => handleChange(e, false)}
            multiline
            rows={1}
          />
          <TextLabel
            style={{ marginTop: "14px" }}
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.note ? error?.note : ""}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CommonTextField
            fontWeight={400}
            text={"એડ્રેસ લિંક"}
            placeholder={"Enter એડ્રેસ લિંક"}
            type="text"
            name="description"
            value={data?.description}
            onChange={(e) => handleChange(e, false)}
            multiline
            rows={1}
            style={{
              maxHeight: "150px", // Adjust the height as needed
              overflow: "auto",
            }}
          />
          <TextLabel
            style={{ marginTop: "14px" }}
            fontSize={"12px"}
            color={"red"}
            fontWeight={"400"}
            title={!data?.description ? error?.description : ""}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "35px",
            }}
          >
            <CommonButton
              width={"280px"}
              text={`${isEdit ? "Update" : "Add"} સંત ભોજન`}
              type="submit"
              onClick={() => (isEdit ? onSubmit(data?._id) : onSubmit())}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddSantBhojan;
