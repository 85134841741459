import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  Table,
  TableRow,
  TableHead,
  TableContainer,
  Box,
  Grid,
  Tabs,
  Tab,
  Tooltip,
  Chip,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { lightTheme } from "../../theme";
import { gujaratiDays, permissionStatus, Roles } from "../../Utils/enum";
import WidgetLoader from "../../Components/Common/widgetLoader";
import CustomPagination from "../../Components/Common/CustomPagination";
import AddSantBhojan from "../../Components/SantBhojan";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import EditForm from "./editForm";
import { Regex } from "../../Utils/regex";
import PdfFormat from "./pdfFormat";
import { pdf } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import ScheduleBhojan from "../../Components/SantBhojan/ScheduleBhojan";
import CommonModal from "../../Components/Common/CommonModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    padding: 5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles()((theme) => {
  return {
    // paddedRow: {
    //   padding: "15px 10px",
    //   whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px'
    // },
    paddedRow: {
      padding: "15px 10px",
    },
    truncate: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "150px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "8px",
      backgroundColor: theme.palette.bgLightExtraPrimary.main,
      color: theme.palette.primary.main,
      cursor: "pointer",
      height: "34px",
    },
    viewBox: {
      borderRadius: "6px",
      padding: "8px",
      color: theme.palette.bgLightExtraSuccess.main,
      backgroundColor: theme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
      height: "35px",
    },
    scheduleIcon: {
      borderRadius: "6px",
      padding: "8px",
      color: theme.palette.bgWhite.main,
      backgroundColor: theme.palette.warning.main,
      cursor: "pointer",
      height: "34px",
      opacity: 0.8,
    },
    viewBoxDisbaled: {
      borderRadius: "6px",
      padding: "8px",
      color: theme.palette.bgGray.main,
      backgroundColor: theme.palette.bgLightBlack.main,
      cursor: "not-allowed",
      height: "34px",
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "8px",
      color: theme.palette.bgLightRed.main,
      backgroundColor: theme.palette.bgLightExtraRed.main,
      cursor: "pointer",
      height: "34px",
    },
    downloadBox: {
      borderRadius: "6px",
      padding: "8px",
      color: theme.palette.bgLightExtraSuccess.main,
      backgroundColor: theme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
      height: "34px",
    },
    closeBox: {
      borderRadius: "6px",
      padding: "8px",
      color: theme.palette.bgLightRed.main,
      backgroundColor: theme.palette.bgLightExtraRed.main,
      cursor: "pointer",
      height: "34px",
    },
  };
});

const SantBhojan = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();

  //States
  const [model, setModel] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [data, setData] = useState({
    name: "",
    surname: "",
    date: dayjs(new Date()).format("YYYY/MM/DD"),
    day: "",
    time: new Date(),
    // time: dayjs(new Date()), // Ensure this is a dayjs object
    phoneNumber: "",
    houseNo: "",
    societyName: "",
    landmark: "",
    area: "",
    note: "",
    mandal: "",
    xetra: "",
    description: "",
    multipleDate: [],
    mahapoojasant: [],
    thalSant: [],
    vehicleDetails: [],
  });
  const [error, setError] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [SantBhojanDetails, setSantBhojanDetails] = useState([]);
  const [santNamavliDetails, setSantNamavliDetails] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [permissions, setPermissions] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadData, setDownloadData] = useState(null);
  const [value, setValue] = React.useState(0);
  const [scheduleBhojanModal, setScheduleBhojanModal] = useState(false);
  const [scheduleBhojanDate, setScheduleBhojanDate] = useState(null);
  const [mahapoojasantChips, setMahapoojasantChips] = useState(
    data?.mahapoojasant || []
  );
  const [thalSantChips, setThalSantChips] = useState(data?.thalSant || []);
  const [vehicleChips, setVehicleChips] = useState(data?.vehicleDetails || []);
  const [searchValue, setSearchValue] = useState({
    ThalSant: "",
    Mahapoojasant: "",
    SantNameofVehicle: "",
  });
  const [searchValueMahapoojasant, setSearchValueMahapoojasant] = useState("");
  const [santBhojanDate, setSantBhojanDate] = useState(null);

  const handleScheduleBhojanDate = (date) => {
    setScheduleBhojanDate(date);
    setSantBhojanDate(dayjs(date));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  //Validation
  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!data?.name) {
      formIsValid = false;
      errors["name"] = "Please enter name.";
    }
    if (!data?.surname) {
      formIsValid = false;
      errors["surname"] = "Please enter surname.";
    }
    if (!data?.phoneNumber) {
      formIsValid = false;
      errors["phoneNumber"] = "Please enter phone number.";
    } else if (!data?.phoneNumber?.match(Regex.mobileNumberRegex)) {
      formIsValid = false;
      errors["phoneNumber"] = "*Please enter valid phone number.";
    }

    if (!data?.houseNo) {
      formIsValid = false;
      errors["houseNo"] = "Please enter houseNo.";
    }
    if (!data?.societyName) {
      formIsValid = false;
      errors["societyName"] = "Please enter societyName.";
    }
    if (!data?.landmark) {
      formIsValid = false;
      errors["landmark"] = "Please enter landmark.";
    }
    if (!data?.area) {
      formIsValid = false;
      errors["area"] = "Please enter area.";
    }

    if (!data?.note) {
      formIsValid = false;
      errors["note"] = "Please enter note.";
    }

    if (!data?.mandal) {
      formIsValid = false;
      errors["mandal"] = "Please enter mandal.";
    }

    if (!data?.xetra) {
      formIsValid = false;
      errors["xetra"] = "Please enter xetra.";
    }


    if (!data?.description) {
      formIsValid = false;
      errors["description"] = "Please enter description.";
    }

    // if (data?.multipleDate?.length == 0) {
    //   formIsValid = false;
    //   errors["multipleDate"] = "Please select date.";
    // }

    if (editForm && value !== 0) {
      if (!data?.time) {
        formIsValid = false;
        errors["time"] = "Please enter time.";
      }

      if (mahapoojasantChips?.length > 0 && mahapoojasantChips.length < 2) {
        formIsValid = false;
        errors["mahapoojasant"] =
          "Please select at least two મહા પૂજા પૂ. સંતો.";
      }

      if (thalSantChips?.length > 0 && thalSantChips.length < 2) {
        formIsValid = false;
        errors["thalSant"] = "Please select at least two થાળ પૂ. સંતો.";
      }

      if (!data?.date) {
        formIsValid = false;
        errors["date"] = "Please select date.";
      }

      if (!data?.day) {
        formIsValid = false;
        errors["day"] = "Please add day.";
      }
      // Validation for driverMobileNo in vehicleDetails
      data.vehicleDetails.forEach((vehicle, index) => {
        if (vehicle.driverMobileNo) {
          if (!vehicle.driverMobileNo.match(Regex.mobileNumberRegex)) {
            formIsValid = false;
            errors[
              `driverMobileNo_${index}`
            ] = `Please enter valid driver mobile number for vehicle ${index + 1
              }.`;
          }
        }
      });
    }

    if (scheduleBhojanModal) {
      if (!santBhojanDate) {
        formIsValid = false;
        errors["scheduleDate"] = "Please select date and time";
      }
    }
    setError(errors);
    console.log(errors, "errors");
    return formIsValid;
  };

  const handleChange = (e, name) => {
    if (name === "time") {
      setData((prevState) => ({ ...prevState, [name]: dayjs(e) }));
    } else if (name) {
      setData((prevState) => ({ ...prevState, [name]: e }));
    } else {
      const { name, value } = e.target;
      if (name === "date") {
        setData((prevState) => ({
          ...prevState,
          [name]: value,
          mahapoojasant: [],
          thalSant: [],
          vehicleDetails: prevState?.vehicleDetails?.map((e) => {
            return {
              ...e,
              santName: [],
            };
          }),
        }));
      } else {
        setData((prevState) => ({ ...prevState, [name]: value }));
      }
    }
  };

  const _getSantNamavli = () => {
    setLoading(true);
    let body = {
      date: new Date(data?.date) || "",
    };
    axios
      .post(`/getSantnamavaliDetails`, body)
      .then((res) => {
        if (res?.data?.data) setSantNamavliDetails(res?.data?.data?.response);
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => setLoading(false));
  };

  // const _getSantNamavli = () => {
  //   setLoading(true);
  //   let body = {
  //     limit: rowsPerPage,
  //     page: page,
  //     search: search || "",
  //   };
  //   axios.post(`/getSantnamavaliDetails`, body)
  //     .then((res) => {
  //       if (res?.data?.data) {
  //         // Filter out already selected values from each dropdown
  //         const filteredSantNamavli = res?.data?.data?.response.filter(sant => {
  //           const selectedIds = [
  //             ...data.mahapoojasant.map(item => item._id),
  //             ...data.thalSant.map(item => item._id),
  //             ...data.vehicleDetails.flatMap(vehicle => vehicle.santName.map(item => item._id))
  //           ];
  //           return !selectedIds.includes(sant._id);
  //         });
  //         setSantNamavliDetails(filteredSantNamavli);
  //       }
  //     })
  //     .catch((err) => {
  //       OnUpdateError(err.data.message || "error");
  //     }).finally(() => setLoading(false));
  // };

  const _getSantBhojan = () => {
    setLoading(true);
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
      tabValue: value,
    };
    axios
      .post(`/getSantBhojanDetails`, body)
      .then((res) => {
        if (res?.data?.data) setSantBhojanDetails(res?.data?.data);
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      })
      .finally(() => setLoading(false));
  };

  const handleClear = () => {
    setData({
      name: "",
      surname: "",
      phoneNumber: "",
      houseNo: "",
      societyName: "",
      landmark: "",
      area: "",
      date: dayjs(new Date()).format("YYYY-MM-DD"),
      day: updateDayInGujarati(),
      note: "",
      mandal: "",
      xetra: "",
      description: "",
      multipleDate: [],

      mahapoojasant: [],
      thalSant: [],
      vehicleDetails: [
        {
          vehicleNumber: "",
          vehicleType: "",
          driverName: "",
          driverMobileNo: "",
          santName: [],
        },
      ],
    });
    setScheduleBhojanDate("");
    setError({});
    setIsEdit(false);
  };

  const _addSantbhojan = () => {
    if (handleValidation()) {
      toggleLoader();
      const body = {
        name: data.name,
        surname: data.surname,
        phoneNumber: data.phoneNumber,
        houseNo: data.houseNo,
        societyName: data.societyName,
        landmark: data.landmark,
        area: data.area,
        // date: new Date(data.date),
        // day: data?.day,
        // time: data?.time ? data?.time : dayjs(new Date()).format("HH:MM"),
        note: data.note,
        mandal: data.mandal,
        xetra: data.xetra,
        description: data.description,
        multipleDate: data?.multipleDate,
      };

      if (editForm) {
        body.mahapoojasant = data?.mahapoojasant
          ?.map((item) => item._id)
          ?.filter((e) => e?.name !== "મહેમાન સંત");
        body.thalSant = data?.thalSant
          ?.map((item) => item._id)
          ?.filter((e) => e?.name !== "મહેમાન સંત");
        body.vehicleDetails = [
          {
            vehicleNumber: "",
            vehicleType: "",
            driverName: "",
            driverMobileNo: "",
            santName: data?.vehicleDetails?.santName
              ?.map((item) => item._id)
              ?.filter((e) => e?.name !== "મહેમાન સંત"),
          },
        ];
      }
      axios
        .post("/addSantbhojan", body)
        .then((res) => {
          swal("Success", res?.data?.message, "success");
          setModel(false);
          _getSantBhojan();
          setData({
            name: "",
            surname: "",
            date: dayjs(new Date()).format("YYYY/MM/DD"),
            day: "",
            time: dayjs(new Date()).format("HH:MM"),
            phoneNumber: "",
            houseNo: "",
            societyName: "",
            landmark: "",
            area: "",
            mandal: "",
            xetra: "",
            note: "",
            description: "",
            multipleDate: [],
          });
          toggleLoader();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  console.log("data", data?.vehicleDetails, mahapoojasantChips, thalSantChips);

  const _editSantbhojan = (id) => {
    if (handleValidation()) {
      const mahapoojasant = mahapoojasantChips?.map((item) => {
        return {
          _id: item._id || null,
          name: item?.name,
        };
      });
      const thalSant = thalSantChips?.map((item) => {
        return {
          _id: item._id || null,
          name: item?.name,
        };
      });
      toggleLoader();
      const body = {
        _id: id,
        name: data?.name,
        surname: data?.surname,
        phoneNumber: data.phoneNumber,
        houseNo: data.houseNo,
        societyName: data.societyName,
        landmark: data.landmark,
        area: data.area,
        note: data.note,
        description: data.description,
        multipleDate: data?.multipleDate,
      };
      if (value !== 0) {
        body.date = data.date;
        body.day = data?.day;
        body.time = data?.time;
        body.mahapoojasant = mahapoojasant;
        body.thalSant = thalSant;
        body.vehicleDetails = data?.vehicleDetails.map((ele) => ({
          ...ele,
          santName: ele.santName,
        }));
      }
      axios
        .post("/updateSantBhojan", body)
        .then((res) => {
          swal("Success", res?.data?.message, "success");
          setEditForm(false);
          _getSantBhojan();
          setData({
            name: "",
            surname: "",
            date: dayjs(new Date()).format("YYYY/MM/DD"),
            day: "",
            time: dayjs(new Date()).format("HH:MM"),
            phoneNumber: "",
            houseNo: "",
            societyName: "",
            landmark: "",
            area: "",
            note: "",
            mandal: "",
            xetra: "",
            description: "",
            multipleDate: [],

            mahapoojasant: [],
            thalSant: [],
            vehicleDetails: [
              {
                vehicleNumber: "",
                vehicleType: "",
                driverName: "",
                driverMobileNo: "",
                santName: [],
              },
            ],
          });
          toggleLoader();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  const _scheduleBhojan = (id, date) => {
    if (handleValidation()) {
      toggleLoader();
      const body = {
        _id: id,
        date: date,
      };
      axios
        .post("/scheduleBhojan", body)
        .then((res) => {
          swal("Success", res?.data?.message, "success");
          setScheduleBhojanModal(false);
          setSantBhojanDate(null);
          _getSantBhojan();
          toggleLoader();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  const _deleteSantBhojan = async (id) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "શું તમે ખરેખર સંત ભોજન કાઢી નાખવા માંગો છો?",
      showCancelButton: true,
      confirmButtonColor: "#0492C2",
      iconColor: "#FF0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        axios
          .delete(`deleteSantBhojan/${id}`)
          .then((res) => {
            if (res?.data?.data) {
              swal("Success", res?.data?.message, "success");
              _getSantBhojan();
            }
          })
          .catch((err) => {
            OnUpdateError(err.data.message);
          })
          .finally(() => {
            toggleLoader();
          });
      }
    });
  };

  const handleEdit = (row) => {
    setEditForm(true);
    setIsEdit(true);

    const mappedVehicleDetails = row?.vehicleDetails.map((vehicle) => ({
      ...vehicle,
      santName: vehicle?.vehicleSantNameDetails || [],
    }));

    setData({
      ...row,
      time: row.time, // Ensure time is a dayjs object
      mahapoojasant: row?.mahapoojaSantDetails || [],
      thalSant: row?.thalSantDetails || [],
      vehicleDetails:
        row?.vehicleDetails?.length > 0
          ? mappedVehicleDetails
          : [
            {
              vehicleNumber: "",
              vehicleType: "",
              driverName: "",
              driverMobileNo: "",
              santName: [],
            },
          ],
    });
    setMahapoojasantChips(row?.mahapoojaSantDetails || []);
    setThalSantChips(row?.thalSantDetails || []);
  };

  const addVehicle = () => {
    setData({
      ...data,
      vehicleDetails: [
        ...data.vehicleDetails,
        {
          vehicleNumber: "",
          vehicleType: "",
          driverName: "",
          driverMobileNo: "",
          santName: [], // Ensure santName is properly initialized
        },
      ],
    });
  };

  const deleteVehicle = (index) => {
    const updatedVehicles = data?.vehicleDetails.filter((_, i) => i !== index);
    setData({ ...data, vehicleDetails: updatedVehicles });
  };

  const onCancle = () => {
    setData({
      name: "",
      surname: "",
      phoneNumber: "",
      houseNo: "",
      societyName: "",
      landmark: "",
      area: "",
      date: dayjs(new Date()).format("YYYY-MM-DD"),
      day: updateDayInGujarati(),
      note: "",
      mandal: "",
      xetra: "",
      description: "",
      multipleDate: [],

      mahapoojasant: [],
      thalSant: [],
      vehicleDetails: [
        {
          vehicleNumber: "",
          vehicleType: "",
          driverName: "",
          driverMobileNo: "",
          santName: [],
        },
      ],
    });
    setError({});
    setModel(false);
    setEditForm(false);
  };

  useEffect(() => {
    _getSantBhojan();
  }, [page, rowsPerPage, search, value]);

  useEffect(() => {
    _getSantNamavli();
  }, [editForm, data?.date]);

  const updateDayInGujarati = (dateString) => {
    const date = new Date(dateString);
    const dayName = date.toLocaleString("en-US", { weekday: "long" });
    setData({ ...data, day: gujaratiDays[dayName] });
    return gujaratiDays[dayName];
  };

  const _updateSantBhojanStatus = (id, status) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: status === 2 ? "success" : "error",
      html:
        status === 2
          ? "શું તમે ખરેખર સંત ભોજન પૂર્ણ કરવા માંગો છો?"
          : "શું તમે ખરેખર સંત ભોજન રદ કરવા માંગો છો?",
      showCancelButton: true,
      confirmButtonColor: "#0492C2",
      iconColor: status === 2 ? "#3CD856" : "#FF0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        const body = {
          _id: id,
          status: status,
        };
        axios
          .post("/updateSantBhojanStatus", body)
          .then((res) => {
            swal("Success", res?.data?.message, "success");
            _getSantBhojan();
            toggleLoader();
          })
          .catch((err) => {
            toggleLoader();
            OnUpdateError(err.data.message);
          });
      }
    });
  };

  useEffect(() => {
    if (data?.date) {
      updateDayInGujarati(data?.date);
    }
  }, [data?.date]);

  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create)
          ? true
          : false,
        update: menuPermissions.includes(permissionStatus.update)
          ? true
          : false,
        delete: menuPermissions.includes(permissionStatus.delete)
          ? true
          : false,
      });
    }
  }, [menuList, location]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setPage(1);
    setRowsPerPage(10);
  };

  const handleDownload = async (data) => {
    const blob = await generatePDFBlob(data);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "document.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generatePDFBlob = async (formData) => {
    const santName = formData?.vehicleDetails?.map((e) => e?.santName)?.flat();
    const mixAllSelectedOption = [
      ...formData?.mahapoojasant,
      ...formData?.thalSant,
      ...santName,
    ];

    const selectedNames = mixAllSelectedOption.map((option) => option?.name);
    console.log("selectedNames", selectedNames);
    const doc = pdf(
      <PdfFormat
        data={{
          ...formData,
          count: selectedNames?.[0] ? selectedNames?.length : 0,
        }}
      />
    );
    const blob = await doc.toBlob();
    return blob;
  };

  return (
    <>
      <PaperContainer elevation={0} square={false}>
        <Grid container>
          <Grid item xs={12}>
            {!model && !editForm && (
              <TableHeading
                title="સંત ભોજન"
                buttonText={permissions?.create ? `Add સંત ભોજન` : ""}
                onClick={() => {
                  setModel(true);
                  setEditForm(false);
                  handleClear();
                }}
                handleSearch={(value) => {
                  setPage(1);
                  setSearch(value);
                }}
              />
            )}
          </Grid>
        </Grid>
        {editForm && (
          <Grid item xs={12}>
            <TableHeading
              title="Update સંત ભોજન"
              buttonText={""}
              handleSearch={(value) => {
                setPage(1);
                setSearch(value);
              }}
              handleBack={() => {
                setEditForm(false);
                setModel(false);
                handleClear();
              }}
              removeSearchField={true}
            />

            {value === 0 ? (
              <AddSantBhojan
                data={data}
                setData={setData}
                error={error}
                handleChange={handleChange}
                onSubmit={!isEdit ? _addSantbhojan : _editSantbhojan}
                isEdit={isEdit}
              />
            ) : (
              <EditForm
                data={data}
                setData={setData}
                santNamavliDetails={santNamavliDetails}
                handleChange={handleChange}
                onEditSubmit={_editSantbhojan}
                addVehicle={addVehicle}
                deleteVehicle={deleteVehicle}
                onCancle={onCancle}
                error={error}
                setError={setError}
                mahapoojasantChips={mahapoojasantChips}
                setMahapoojasantChips={setMahapoojasantChips}
                thalSantChips={thalSantChips}
                setThalSantChips={setThalSantChips}
                vehicleChips={vehicleChips}
                setVehicleChips={setVehicleChips}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
          </Grid>
        )}

        {model && (
          <Grid item xs={12}>
            <TableHeading
              title={"Add સંત ભોજન"}
              buttonText={""}
              handleBack={() => {
                setEditForm(false);
                setModel(false);
                handleClear();
              }}
              removeSearchField
            />
            <AddSantBhojan
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              onSubmit={_addSantbhojan}
              isEdit={isEdit}
            />
          </Grid>
        )}

        {scheduleBhojanModal && (
          <CommonModal
            open={scheduleBhojanModal}
            onClose={() => {
              setScheduleBhojanModal(false);
              setSantBhojanDate(null);
              handleClear();
            }}
            title={"Schedule સંત ભોજન"}
            content={
              <ScheduleBhojan
                data={data}
                error={error}
                handleChange={handleScheduleBhojanDate}
                onSubmit={_scheduleBhojan}
                scheduleBhojanDate={scheduleBhojanDate}
                santBhojanDate={santBhojanDate}
                setSantBhojanDate={setSantBhojanDate}
              />
            }
          />
        )}

        {!(editForm || model) && (
          <>
            {user?.userType !== Roles?.Admin && (
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                      variant="scrollable"
                    >
                      <Tab label="TO DO" {...a11yProps(0)} />
                      <Tab label="Scheduled" {...a11yProps(1)} />
                      <Tab label="Completed" {...a11yProps(2)} />
                      <Tab label="Cancelled" {...a11yProps(3)} />
                    </Tabs>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <TableContainer>
                {!loading ? (
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          className={`${classes.paddedRow} ${classes.truncate}`}
                        >
                          No.
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          નામ
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          અટક
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          મોબાઈલ.નં
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          ઘર/મક।ન નંબર.
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          સોસાયટી/એપાર્ટમેન્ટ નું નામ
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          સ્થાન દ્વારા નજીક
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          વિસ્તાર
                        </StyledTableCell>
                        {value != 0 && (
                          <StyledTableCell className={classes.truncate}>
                            તારીખ
                          </StyledTableCell>
                        )}
                        <StyledTableCell className={classes.truncate}>
                          નોંધ
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          મંડળ
                        </StyledTableCell>
                        <StyledTableCell className={classes.truncate}>
                          ક્ષેત્ર
                        </StyledTableCell>
                        {value === 0 && user?.userType !== Roles?.Admin && (
                          <StyledTableCell align="center">
                            તારીખ અનુસૂચિ
                          </StyledTableCell>
                        )}
                        {value === 1 && (
                          <StyledTableCell align="center">
                            {" "}
                            પૂર્ણ/રદ કરો{" "}
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {SantBhojanDetails?.response?.length > 0 ? (
                        SantBhojanDetails?.response?.map((row, index) => {
                          return (
                            <StyledTableRow
                              style={{
                                background:
                                  (row?.mahapoojasant?.length > 0 ||
                                    row?.thalSantDetails?.length > 0 ||
                                    row?.vehicleDetails?.[0]?.santName?.length >
                                    0) &&
                                  value === 1 &&
                                  "#f8ecec",
                              }}
                              key={index}
                            >
                              <StyledTableCell
                                className={`${classes.paddedRow} ${classes.truncate}`}
                              >
                                {(index % rowsPerPage) +
                                  1 +
                                  (page - 1) * rowsPerPage}
                              </StyledTableCell>
                              <StyledTableCell className={classes.truncate}>
                                {row.name}
                              </StyledTableCell>
                              <StyledTableCell className={classes.truncate}>
                                {row.surname}
                              </StyledTableCell>
                              <StyledTableCell className={classes.truncate}>
                                {row.phoneNumber}
                              </StyledTableCell>
                              <StyledTableCell className={classes.truncate}>
                                {row.houseNo}
                              </StyledTableCell>
                              <StyledTableCell className={classes.truncate}>
                                {row.societyName}
                              </StyledTableCell>
                              <StyledTableCell className={classes.truncate}>
                                {row.landmark}
                              </StyledTableCell>
                              <StyledTableCell className={classes.truncate}>
                                {row.area}
                              </StyledTableCell>
                              {value != 0 && (
                                <StyledTableCell
                                  className={classes.truncate}
                                  style={{
                                    overflowX: "auto",
                                    maxWidth: "200px",
                                    cursor: "grab",
                                    height: "40px",
                                  }}
                                >
                                  {/* {value == 0 ?
                                  <Box display={'flex'} gap={1} alignItems={'center'} width={'200px'}>
                                    {row?.multipleDate?.map((e) => {
                                      return <Chip label={dayjs(e).format("DD/MM/YYYY")} size="small" />
                                    })}
                                  </Box>
                                  : */}
                                  {dayjs(row?.date).format("DD/MM/YYYY")}
                                  {/* } */}
                                </StyledTableCell>
                              )}
                              <Tooltip
                                title={row.note}
                                arrow
                                slotProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -14],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              >
                                <StyledTableCell className={classes.truncate}>
                                  {row.note}
                                </StyledTableCell>
                              </Tooltip>
                              <StyledTableCell className={classes.truncate}>
                                  {row.mandal}
                                </StyledTableCell>
                                <StyledTableCell className={classes.truncate}>
                                  {row.xetra}
                                </StyledTableCell>
                              {value === 0 &&
                                user?.userType !== Roles?.Admin && (
                                  <StyledTableCell align="center">
                                    <Box
                                      display={"flex"}
                                      justifyContent={"center"}
                                      gap={1}
                                    >
                                      <Assets
                                        className={classes.scheduleIcon}
                                        src={"/assets/icons/calendar-edit.svg"}
                                        absolutePath={true}
                                        onClick={() => {
                                          setScheduleBhojanModal(true);
                                          setData(row);
                                        }}
                                      />
                                    </Box>
                                  </StyledTableCell>
                                )}
                              {value === 1 && (
                                <StyledTableCell>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                    gap={1}
                                  >
                                    {row?.mahapoojasant?.length > 0 ||
                                      row?.thalSantDetails?.length > 0 ||
                                      row?.vehicleDetails?.[0]?.santName?.length >
                                      0 ? (
                                      <Assets
                                        className={classes.viewBox}
                                        src={"/assets/icons/right.svg"}
                                        absolutePath={true}
                                        onClick={() => {
                                          _updateSantBhojanStatus(row?._id, 2);
                                        }}
                                      />
                                    ) : (
                                      <Assets
                                        className={classes.viewBoxDisbaled}
                                        src={"/assets/icons/right.svg"}
                                        absolutePath={true}
                                        onClick={() => { }}
                                      />
                                    )}
                                    <Assets
                                      className={classes.closeBox}
                                      src={"/assets/icons/close.svg"}
                                      absolutePath={true}
                                      onClick={() => {
                                        _updateSantBhojanStatus(row?._id, 3);
                                      }}
                                    />
                                  </Box>
                                </StyledTableCell>
                              )}

                              <StyledTableCell>
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  gap={1}
                                >
                                  {permissions?.update &&
                                    (value === 0 || value === 1) && (
                                      <Assets
                                        className={classes.writeBox}
                                        src={"/assets/icons/write.svg"}
                                        absolutePath={true}
                                        onClick={() => {
                                          handleEdit(row);
                                        }}
                                      />
                                    )}
                                  {permissions?.delete && value === 0 && (
                                    <Assets
                                      className={classes.deleteBox}
                                      src={"/assets/icons/delete.svg"}
                                      absolutePath={true}
                                      onClick={() =>
                                        _deleteSantBhojan(row?._id)
                                      }
                                    />
                                  )}
                                  {value !== 0 && (
                                    <Assets
                                      className={classes.downloadBox}
                                      src={"/assets/icons/downloadIcon.svg"}
                                      absolutePath={true}
                                      onClick={() => {
                                        handleDownload(row);
                                      }}
                                    />
                                  )}
                                </Box>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            {" "}
                            <DataNotFound
                              icon={
                                <ErrorOutlineIcon
                                  color="primary"
                                  style={{ fontSize: "3rem" }}
                                />
                              }
                              elevation={0}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  <WidgetLoader />
                )}
              </TableContainer>
            </Grid>
          </>
        )}

        {SantBhojanDetails?.count > 0 && !(editForm || model) && (
          <Grid item xs={12}>
            <CustomPagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={Math.ceil(SantBhojanDetails?.count / rowsPerPage)}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}
      </PaperContainer>

      {/* {model && (
        <CommonModal
          open={model}
          onClose={handleClear}
          title={`${isEdit ? "Update" : "Add"} સંત ભોજન`}
          content={
            <AddSantBhojan
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              onSubmit={_addSantbhojan}
              isEdit={isEdit}
            />
          }
        />
      )
      } */}
    </>
  );
};

export default SantBhojan;
