import React from 'react'

const NewFileIcon = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.3334 13.3337V20.0003C29.3334 26.667 26.6667 29.3337 20 29.3337H12C5.33335 29.3337 2.66669 26.667 2.66669 20.0003V12.0003C2.66669 5.33366 5.33335 2.66699 12 2.66699H18.6667" stroke={props?.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M29.3334 13.3337H24C20 13.3337 18.6667 12.0003 18.6667 8.00033V2.66699L29.3334 13.3337Z" stroke={props?.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.33331 17.333H17.3333" stroke={props?.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.33331 22.667H14.6666" stroke={props?.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default NewFileIcon